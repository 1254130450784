import React, {useState, useEffect, useRef} from 'react';
import css from '../commu.module.css';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_GRAPHQL } from '../../../../constants';

const CoachCommunication = ({ user }) => {
    const userID = user.id;
    const [loadingConversations, setLoadingConversations] = useState(false);
    const [conversationsError, setConversationsError] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    const coachID = user.coachID;
    const [clients, setClients] = useState([]);
    const [newClientID, setNewClientID] = useState(null);

    const query = `query{
        communications(filters: {users:{id:{eq:${userID}}}}){
            data{
                id
                attributes{
                    users {
                        data {
                            id
                            attributes {
                                username
                            }
                        }
                    }
                    messages
                }
            }
        }
    }`;

    useEffect(() => {
        async function getConversations() {
            setLoadingConversations(true);
            setLoadingMessages(true);
            setConversationsError(false);
            try {
                const response = await axiosAuthFetch(API_GRAPHQL, { query }, 'POST');
                const communicationsData = response.data.communications.data;

                const updatedCommunicationsData = communicationsData.map(communication => {
                    return {
                        ...communication,
                        attributes: {
                            ...communication.attributes,
                            messages: JSON.parse(communication.attributes.messages)
                        }
                    };
                });

                setConversations(updatedCommunicationsData);
            } catch (error) {
                console.error(error);
                setConversationsError(true);
                setMessageError(true);
            } finally {
                setLoadingConversations(false);
                setLoadingMessages(false);
            }
        }
        getConversations();
    }, [query]);

    const handleConversationSelect = (conversation) => {
        setSelectedConversation(conversation);
        setMessages(conversation.attributes.messages);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;
    
        const messageToSend = {
            message: newMessage,
            senderId: userID,
            timestamp: new Date().toISOString(),
        };
    
        const mutation = `
            mutation UpdateCommunication($id: ID!, $messages: JSON) {
                updateCommunication(id: $id, data: { messages: $messages }) {
                    data {
                        attributes {
                            messages
                        }
                    }
                }
            }
        `;
    
        const updatedMessages = JSON.stringify([...messages, messageToSend]);

        const variables = {
            id: selectedConversation.id,
            messages: updatedMessages,
        };
    
        try {
            const response = await axiosAuthFetch(API_GRAPHQL, {
                query: mutation,
                variables: variables
            }, 'POST');
    
            const messagesString = response.data.updateCommunication.data.attributes.messages;
            const parsedMessages = JSON.parse(messagesString);
            setMessages(parsedMessages);
            setNewMessage('');
        } catch (error) {
            console.error("Failed to send message:", error);
            setMessageError(true);
        }
    };

    const fetchClients = async () => {
        const query = ` 
            query{
                coaches(filters: {id:{eq:${coachID}}}) {
                    data{
                        id
                        attributes{
                            clients(pagination: {limit: 100}){
                                data{
                                    id
                                    attributes{
                                        username
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `; 
        try {
            const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
            const filteredClients = response.data.coaches.data[0].attributes.clients.data.filter(client => client.id !== userID.toString());
            setClients(filteredClients);
        } catch(error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchClients();
        //eslint-disable-next-line
    }, []);

    const handleStartConversation = async () => {
        if (!newClientID) return;

        const existingConversation = conversations.find(conversation =>
            conversation.attributes.users.data.some(user => user.id === newClientID)
        );
    
        if (existingConversation) {
            setSelectedConversation(existingConversation);
            setMessages(existingConversation.attributes.messages);
            return;
        }
    
        const mutation = `
            mutation CreateCommunication($users: [ID!]!) {
                createCommunication(data: { users: $users, messages: "[]", publishedAt: "${new Date().toISOString()}" }) {
                    data {
                        id
                        attributes {
                            users {
                                data {
                                    id
                                    attributes {
                                        username
                                    }
                                }
                            }
                            messages
                        }
                    }
                }
            }
        `;
    
        const variables = {
            users: [userID, parseInt(newClientID, 10)],
        };
    
        try {
            const response = await axiosAuthFetch(API_GRAPHQL, {
                query: mutation,
                variables: variables
            }, 'POST');
    
            const newConversation = response.data.createCommunication.data;
            newConversation.attributes.messages = JSON.parse(newConversation.attributes.messages);
            setConversations([...conversations, newConversation]);
            setSelectedConversation(newConversation);
            setMessages(newConversation.attributes.messages);
        } catch (error) {
            console.error("Failed to create conversation:", error);
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const availableClients = clients.filter(client =>
        !conversations.some(conversation =>
            conversation.attributes.users.data.some(user => user.id === client.id)
        )
    );

    if (loadingConversations) {
        return (<div>Laster samtaler...</div>);
    }
    if (conversationsError) {
        return (<div>Oida, samtalene kunne ikke lastes! Venligst prøv igjen!</div>);
    }

    return (
        <div className={`${css.container} appearing-container`}>
            <div className={css.sub1}>
                <div className={css.overskrift}>SAMTALER</div>
                <div className={css.conversationsList}>
                    {conversations.map((conversation) => {
                        const users = conversation.attributes.users.data;
                        console.log("Users in conversation:", users);

                        const client = users.find(user => user.id.toString() !== userID.toString());
                        console.log("Client found:", client);
                        return (
                            <div
                                key={conversation.id}
                                className={`${css.conversationItem} ${selectedConversation && selectedConversation.id === conversation.id ? css.selectedConversation : ''}`}
                                onClick={() => handleConversationSelect(conversation)}
                            >
                                {client ? client.attributes.username : "Ukjent Klient"}
                            </div>
                        );
                    })}
                </div>
                <div className={css.startConversationContainer}>
                    <select className={css.clientSelect} onChange={(e) => setNewClientID(e.target.value)} value={newClientID}>
                        <option value="">Velg en klient</option>
                        {availableClients.map(client => (
                            <option key={client.id} value={client.id}>{client.attributes.username}</option>
                        ))}
                    </select>
                    <button className={css.nySamtale} onClick={handleStartConversation}>Start ny samtale</button>
                </div>
            </div>
            <div className={css.window}>
                {selectedConversation && (
                    <div className={css.innerWindow}>
                        {loadingMessages && <div>Laster meldinger...</div>}
                        {messageError && <div>Oida, meldingene kunne ikke lastes! Venligst prøv igjen!</div>}
                        {!loadingMessages && !messageError && messages.length > 0 && (
                            <div className={css.messagesWindow}>
                                {messages.map((msg, index) => (
                                    <div
                                        key={index}
                                        className={`${css.message} ${msg.senderId === userID ? css.userMessage : css.otherMessage}`}
                                    >
                                        {msg.message}
                                    </div>
                                ))}
                                <div ref={messagesEndRef} />
                            </div>
                        )}
                        <div className={css.messageInputContainer}>
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                className={css.messageInput}
                                placeholder="Skriv en melding..."
                            />
                            <button onClick={handleSendMessage} className={css.sendButton}>Send</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CoachCommunication;


