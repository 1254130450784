import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';

const AdminCoachApproval = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // Henter brukere uten coachID
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosAuthFetch(`${API_BASE_URL}/users`, {}, 'GET');
        console.log(response);
        const filteredUsers = response.filter(user => user.isCoach === true && !user.coachID);
        setUsers(filteredUsers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setErrorMessage('Kunne ikke hente brukere.');
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Godkjenn bruker som coach
  const approveCoach = async (userId) => {
    try {
      // Opprett ny Coach i Strapi
      const coachResponse = await axiosAuthFetch(`${API_BASE_URL}/coaches`, {
        data: { user: userId }
      }, 'POST');
      
      const coachID = coachResponse.data.id;

      // Oppdater bruker med coachID
      await axiosAuthFetch(`${API_BASE_URL}/users/${userId}`, {
        coachID: coachID,
        role: 3,
      }, 'PUT');

      // Oppdater lokal state for å fjerne brukeren fra listen
      setUsers(users.filter(user => user.id !== userId));
      alert('Brukeren har blitt godkjent som coach!');
    } catch (error) {
      console.error('Error approving coach:', error);
      setErrorMessage('Kunne ikke godkjenne brukeren.');
    }
  };

// const approveCoach = async (userId) => {
//     try {
//       // Bruk det tilpassede endepunktet i Strapi for å godkjenne coach
//       await axiosAuthFetch(`${API_BASE_URL}/users/approve-coach/${userId}`, {}, 'POST');
  
//       // Oppdater lokal state for å fjerne brukeren fra listen
//       setUsers(users.filter(user => user.id !== userId));
//       alert('Brukeren har blitt godkjent som coach!');
//     } catch (error) {
//       console.error('Error approving coach:', error);
//       setErrorMessage('Kunne ikke godkjenne brukeren.');
//     }
//   };

  const denyCoach = async (userId) => {
    try {
      // Slett brukeren
      await axiosAuthFetch(`${API_BASE_URL}/users/${userId}`, {}, 'DELETE');

      // Oppdater lokal state for å fjerne brukeren fra listen
      setUsers(users.filter(user => user.id !== userId));
      alert('Brukeren har blitt avvist og slettet.');
    } catch (error) {
      console.error('Error denying coach:', error);
      setErrorMessage('Kunne ikke avvise brukeren.');
    }
  };

  if (loading) return <p>Laster inn...</p>;
  if (errorMessage) return <p>{errorMessage}</p>;

  return (
    <div className={styles.container}>
      <h2>Godkjenn Coach-brukere</h2>
      {users.length > 0 ? (
        users.map(user => (
          <div key={user.id} className={styles.coachCard}>
            <p>Navn: {user.username}</p>
            <p>E-post: {user.email}</p>
            <div className={styles.buttonDiv}>
                <button className={styles.approveButton} onClick={() => approveCoach(user.id)}>Godkjenn</button>
                <button className={styles.denyButton} onClick={() => denyCoach(user.id)}>Avvis</button>
            </div>
          </div>
        ))
      ) : (
        <p>Ingen brukere trenger godkjenning.</p>
      )}
    </div>
  );
};

export default AdminCoachApproval;
