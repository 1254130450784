// import React, { useState, useEffect } from 'react';
// import { axiosAuthFetch } from '../../../helpers/axios';
// import Loader from '../../global/loader';
// import styles from './styles.module.css';
// import { getLocalStorageItem } from '../../global/storage';
// import { API_BASE_URL } from '../../../constants';
// import CustomAlertModal from '../../global/customModal';

// export default function WorkoutHistoryPage() {
//   const [workoutSessions, setWorkoutSessions] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isError, setIsError] = useState(false);
//   const [selectedSession, setSelectedSession] = useState(null);
//   const [showModal, setShowModal] = useState(false);

//   const userInfo = getLocalStorageItem('limitless-user');
//   const clientID = userInfo.user.id;

//   useEffect(() => {
//     async function fetchWorkoutHistory() {
//       setIsLoading(true);
//       setIsError(false);
//       try {
//         const response = await axiosAuthFetch(
//           `${API_BASE_URL}/workout-sessions?filters[client][id][$eq]=${clientID}&populate[program][populate][cover_image][populate]=images&populate[exercise_logs][populate]=exercise.image`,
//           {},
//           'GET'
//         );
//         const sortedSessions = response.data.sort((a, b) => {
//           return new Date(b.attributes.date_performed) - new Date(a.attributes.date_performed);
//         });
//         setWorkoutSessions(sortedSessions);
//       } catch (error) {
//         console.error(error);
//         setIsError(true);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//     fetchWorkoutHistory();
//   }, [clientID]);

//   const openSessionModal = (session) => {
//     setSelectedSession(session);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setSelectedSession(null);
//     setShowModal(false);
//   };

//   const calculateTotalWeight = () => {
//     if (!selectedSession) return 0;

//     return selectedSession.attributes.exercise_logs.data.reduce((total, log) => {
//       const { reps, vekt } = log.attributes;
//       return total + reps * vekt;
//     }, 0);
//   };

//   // Group and sort exercises by the 'order' field, ensuring exercises follow their defined order
//   const groupExerciseLogsByOrder = (exerciseLogs) => {
//     const groupedExercises = {};

//     exerciseLogs.forEach((log) => {
//       const exerciseId = log.attributes.exercise.data.id;
//       const exerciseName = log.attributes.exercise.data.attributes.name;
//       const exerciseImageUrl = log.attributes.exercise.data.attributes.image.data[0]?.attributes?.url || '';
//       const order = log.attributes.order;  // Capture the order field

//       if (!groupedExercises[exerciseId]) {
//         groupedExercises[exerciseId] = {
//           exerciseName,
//           exerciseImageUrl,
//           order,  // Store the order with the grouped exercise
//           sets: [],
//         };
//       }

//       // Push all sets for the same exercise together
//       groupedExercises[exerciseId].sets.push({
//         sett: log.attributes.sett,
//         reps: log.attributes.reps,
//         vekt: log.attributes.vekt,
//       });
//     });

//     // Sort the exercises by the 'order' field before returning them
//     return Object.values(groupedExercises).sort((a, b) => a.order - b.order);
//   };

//   if (isLoading) {
//     return (
//       <div>
//         <Loader />
//       </div>
//     );
//   }

//   if (isError) {
//     return <div>Oida, her har det skjedd noe galt!</div>;
//   }

//   return (
//     <div className={styles.container1}>
//       <p className={styles.overskrift}>DINE ØKTER</p>
//       <div className={styles.programList}>
//         {workoutSessions.length === 0 ? (
//           <p>Du har ingen fullførte økter ennå.</p>
//         ) : (
//           workoutSessions.map((session) => {
//             const { id, attributes } = session;
//             const { date_performed, program, name } = attributes;
//             const coverImageUrl =
//               program.data.attributes.cover_image.data.attributes.images.data[0].attributes.url;
//             const formattedDate = new Date(date_performed).toLocaleDateString('no-NO');

//             return (
//               <div
//                 key={id}
//                 className={styles.programItem}
//                 onClick={() => openSessionModal(session)}
//                 style={{ backgroundImage: `url(${coverImageUrl})` }}
//               >
//                 <div className={styles.text}>
//                   <p>{name}</p>
//                   <p>{formattedDate}</p>
//                 </div>
//               </div>
//             );
//           })
//         )}
//       </div>

//       {selectedSession && (
//         <CustomAlertModal
//           show={showModal}
//           handleClose={closeModal}
//           content={
//             <div className={styles.modalContent}>
//                  <div className={styles.headerContainer} style={{
//                   backgroundImage: selectedSession.attributes.program.data.attributes.cover_image.data &&
//                   selectedSession.attributes.program.data.attributes.cover_image.data.attributes.images.data[0]
//                     ? `url(${selectedSession.attributes.program.data.attributes.cover_image.data.attributes.images.data[0].attributes.url})`
//                     : 'none'
//                 }}>
//                     <h2 className={styles.headerNavn}>{selectedSession.attributes.program.data.attributes.programName}</h2>
//                     <div className={styles.timerContainer}>
//                         <div className={styles.timer}>
//                         Tid: {selectedSession.attributes.duration || 'N/A'}
//                         </div>
//                     </div>
//                 </div>
//               <div className={styles.inputRating}>
//                 <label>Rating:</label>
//                 <StarRating
//                   rating={selectedSession.attributes.rating || 0}
//                   setRating={() => {}}
//                   readOnly
//                 />
//               </div>
//               {groupExerciseLogsByOrder(selectedSession.attributes.exercise_logs.data).map(
//                 (exerciseData, index) => (
//                   <div key={index} className={styles.exerciseCard}>
//                     <div className={styles.cardHeader}>
//                       <div
//                         className={styles.cardHeaderImageContainer}
//                         style={{ backgroundImage: `url(${exerciseData.exerciseImageUrl})` }}
//                       />
//                       <div className={styles.exerciseNameDiv}>
//                         <p className={styles.exerciseName}>{exerciseData.exerciseName}</p>
//                       </div>
//                     </div>
//                     <div className={styles.subHeader}>
//                       <div className={styles.subSet}>Sett</div>
//                       <div className={styles.subReps}>Reps utført</div>
//                       <div className={styles.subReps}>Vekt</div>
//                     </div>
//                     {exerciseData.sets.map((set, setIndex) => (
//                       <div key={setIndex} className={styles.set}>
//                         {set.sett}.
//                         <div className={styles.presetReps}>{set.reps}</div>
//                         <div className={styles.presetReps}>{set.vekt} kg</div>
//                       </div>
//                     ))}
//                   </div>
//                 )
//               )}
//               <div className={styles.totalWeight}>
//                 <p>Total vekt løftet: {calculateTotalWeight()} kg</p>
//               </div>
//             </div>
//           }
//           buttonLabel="Lukk"
//           submit={closeModal}
//           modalTitle="Økt detaljer"
//         />
//       )}
//     </div>
//   );
// }

// const StarRating = ({ rating, setRating, readOnly = false }) => {
//   const handleRating = (index) => {
//     if (!readOnly) {
//       setRating(index + 1);
//     }
//   };

//   return (
//     <div className={styles.stars}>
//       {[...Array(5)].map((star, index) => {
//         return (
//           <span
//             key={index}
//             className={`${styles.star} ${index < rating ? styles.filled : ''} ${
//               readOnly ? styles.readOnly : ''
//             }`}
//             onClick={() => handleRating(index)}
//           >
//             ★
//           </span>
//         );
//       })}
//     </div>
//   );
// };


import React, { useState, useEffect } from 'react';
import { axiosAuthFetch } from '../../../helpers/axios';
import Loader from '../../global/loader';
import styles from './styles.module.css';
import { getLocalStorageItem } from '../../global/storage';
import { API_BASE_URL } from '../../../constants';
// import CustomAlertModal from '../../global/customModal';
import BottomSheetModal from '../bottomSheetModal';

export default function WorkoutHistoryPage() {
  const [workoutSessions, setWorkoutSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  //const [showModal, setShowModal] = useState(false);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

  const openModal = () => setBottomSheetOpen(true);
  //const closeModal = () => setBottomSheetOpen(false);

  const userInfo = getLocalStorageItem('limitless-user');
  const clientID = userInfo.user.id;

  useEffect(() => {
    async function fetchWorkoutHistory() {
      setIsLoading(true);
      setIsError(false);
      try {
        const response = await axiosAuthFetch(
          `${API_BASE_URL}/workout-sessions?filters[client][id][$eq]=${clientID}&populate[program][populate][cover_image][populate]=images&populate[exercise_logs][populate]=exercise.image`,
          {},
          'GET'
        );
        const sortedSessions = response.data.sort((a, b) => {
          return new Date(b.attributes.date_performed) - new Date(a.attributes.date_performed);
        });
        setWorkoutSessions(sortedSessions);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchWorkoutHistory();
  }, [clientID]);

  const openSessionModal = (session) => {
    setSelectedSession(session);
    openModal();
  };

  const closeModal = () => {
    setSelectedSession(null);
    setBottomSheetOpen(false);
  };

  const calculateTotalWeight = () => {
    if (!selectedSession) return 0;

    return selectedSession.attributes.exercise_logs.data.reduce((total, log) => {
      const { reps, vekt } = log.attributes;
      return total + reps * vekt;
    }, 0);
  };

  const deleteWorkoutSession = async () => {
    if (!selectedSession) return;

    const confirmDelete = window.confirm("Er du sikker på at du vil slette denne økten?");
    if (!confirmDelete) return;

    try {
      await axiosAuthFetch(`${API_BASE_URL}/workout-sessions/${selectedSession.id}`, {}, 'DELETE');
      setWorkoutSessions(workoutSessions.filter((session) => session.id !== selectedSession.id));
      closeModal();
      alert("Økten er slettet.");
    } catch (error) {
      console.error("Error deleting workout session:", error);
      alert("Kunne ikke slette økten. Prøv igjen senere.");
    }
  };

  // Group and sort exercises by the 'order' field, ensuring exercises follow their defined order
  const groupExerciseLogsByOrder = (exerciseLogs) => {
    const groupedExercises = {};

    exerciseLogs.forEach((log) => {
      const exerciseId = log.attributes.exercise.data.id;
      const exerciseName = log.attributes.exercise.data.attributes.name;
      const exerciseImageUrl = log.attributes.exercise.data.attributes.image.data[0]?.attributes?.url || '';
      const order = log.attributes.order;

      if (!groupedExercises[exerciseId]) {
        groupedExercises[exerciseId] = {
          exerciseName,
          exerciseImageUrl,
          order,
          sets: [],
        };
      }

      groupedExercises[exerciseId].sets.push({
        sett: log.attributes.sett,
        reps: log.attributes.reps,
        vekt: log.attributes.vekt,
      });
    });

    return Object.values(groupedExercises).sort((a, b) => a.order - b.order);
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return <div>Oida, her har det skjedd noe galt!</div>;
  }

  return (
    <div className={styles.container1}>
      <p className={styles.overskrift}>DINE ØKTER</p>
      <div className={styles.programList}>
        {workoutSessions.length === 0 ? (
          <p>Du har ingen fullførte økter ennå.</p>
        ) : (
          workoutSessions.map((session) => {
            const { id, attributes } = session;
            const { date_performed, program, name } = attributes;
            const coverImageUrl =
              program.data.attributes.cover_image.data.attributes.images.data[0].attributes.url;
            const formattedDate = new Date(date_performed).toLocaleDateString('no-NO');

            return (
              <div
                key={id}
                className={styles.programItem}
                onClick={() => openSessionModal(session)}
                style={{ backgroundImage: `url(${coverImageUrl})` }}
              >
                <div className={styles.text}>
                  <p>{name}</p>
                  <p>{formattedDate}</p>
                </div>
              </div>
            );
          })
        )}
      </div>

      {selectedSession && (
        <BottomSheetModal isOpen={bottomSheetOpen} onClose={closeModal} breakpoints={[0.9]} initialBreakpointIndex={0}>
            <div className={styles.modalContent}>
              <div
                className={styles.headerContainer}
                style={{
                  backgroundImage:
                    selectedSession.attributes.program.data.attributes.cover_image.data &&
                    selectedSession.attributes.program.data.attributes.cover_image.data.attributes.images.data[0]
                      ? `url(${selectedSession.attributes.program.data.attributes.cover_image.data.attributes.images.data[0].attributes.url})`
                      : 'none',
                }}
              >
                <h2 className={styles.headerNavn}>
                  {selectedSession.attributes.program.data.attributes.programName}
                </h2>
                <div className={styles.timerContainer}>
                  <div className={styles.timer}>
                    Tid: {selectedSession.attributes.duration || 'N/A'}
                  </div>
                </div>
              </div>
              <div className={styles.inputRating}>
                <label>Rating:</label>
                <StarRating rating={selectedSession.attributes.rating || 0} setRating={() => {}} readOnly />
              </div>
              {groupExerciseLogsByOrder(selectedSession.attributes.exercise_logs.data).map(
                (exerciseData, index) => (
                  <div key={index} className={styles.exerciseCard}>
                    <div className={styles.cardHeader}>
                      <div
                        className={styles.cardHeaderImageContainer}
                        style={{ backgroundImage: `url(${exerciseData.exerciseImageUrl})` }}
                      />
                      <div className={styles.exerciseNameDiv}>
                        <p className={styles.exerciseName}>{exerciseData.exerciseName}</p>
                      </div>
                    </div>
                    <div className={styles.subHeader}>
                      <div className={styles.subSet}>Sett</div>
                      <div className={styles.subReps}>Reps utført</div>
                      <div className={styles.subReps}>Vekt</div>
                    </div>
                    {exerciseData.sets.map((set, setIndex) => (
                      <div key={setIndex} className={styles.set}>
                        {set.sett}.
                        <div className={styles.presetReps}>{set.reps}</div>
                        <div className={styles.presetReps}>{set.vekt} kg</div>
                      </div>
                    ))}
                  </div>
                )
              )}
              <div className={styles.totalWeight}>
                <p>Total vekt løftet: {calculateTotalWeight()} kg</p>
              </div>
              <div className={styles.comments}>
                <div>
                  <strong>Din kommentar: </strong>
                  {selectedSession.attributes.clients_comments}
                </div>
                <div>
                  <strong>Coach's kommentar: </strong>
                  {selectedSession.attributes.coach_comments}
                </div>
              </div>
              <button onClick={deleteWorkoutSession} className={styles.deleteButton}>
                Slett økt
              </button>
            </div>
        </BottomSheetModal>
      )}
    </div>
  );
}

const StarRating = ({ rating, setRating, readOnly = false }) => {
  const handleRating = (index) => {
    if (!readOnly) {
      setRating(index + 1);
    }
  };

  return (
    <div className={styles.stars}>
      {[...Array(5)].map((star, index) => {
        return (
          <span
            key={index}
            className={`${styles.star} ${index < rating ? styles.filled : ''} ${
              readOnly ? styles.readOnly : ''
            }`}
            onClick={() => handleRating(index)}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};
