import React, {useState, useEffect} from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL } from '../../../constants';
import { filterObjects } from '../searchFunctionality';
import ExerciseCards from '../../exerciseCards';
import Loader from '../loader';
import { getLocalStorageItem } from '../storage';
import CreateExerciseForm from '../forms/createExercise';
import CustomAlertModal from '../customModal';

export default function ExerciseBank() {
    const [exercises, setExercises] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = getLocalStorageItem('limitless-user');
    const isAdmin = userInfo.user.isAdmin;
    const [openAddExerciseModal, setOpenAddExerciseModal] = useState(false);

    useEffect(() => {
        async function getExercises() {
            try{
                setIsLoading(true);
                const response = await axiosAuthFetch(`${API_BASE_URL}/exercises?populate=*`,{}, 'GET');
                console.log('exercises', response.data);
                setExercises(response.data);
            } catch(error){
                console.log(error);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        }

        getExercises();

        //eslint-disable-next-line
    },[]);

    const searchedExercises = filterObjects(searchText, exercises);

    const handleOpenAddExerciseModal = () => {
        setOpenAddExerciseModal(true);
    }

    const handleCloseAddExerciseModal = () => {
        setOpenAddExerciseModal(false);
    }

    return(
        <div className={styles.container}>
            {isLoading && <Loader />  }
            <div className={styles.sub1}>
                <div className={styles.subHeader}>Øvelsesbank</div>
                <div className={styles.subChild}>
                    <input
                        className={styles.search}
                        type="text"
                        placeholder="Søk etter øvelse"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    {isAdmin && (
                        <div className={styles.addButton} onClick={handleOpenAddExerciseModal}>
                            Legg til øvelse
                        </div>
                    )}
                    <CustomAlertModal 
                        content={<CreateExerciseForm />}
                        show={openAddExerciseModal}
                        handleClose={handleCloseAddExerciseModal}
                    />
                </div>
                <ExerciseCards exercises={searchedExercises} />
            </div>
        </div>
    )
}