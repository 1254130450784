import React from 'react';
//import HomePageCards from '../../global/homePageCards';
//import ClientHomePage from '../../global/clientHomePage';
//import ClientsPage from '../coachesHomePage';
import CoachesHomePage from '../coachesHomePage';
import ClientHomePage from '../../global/clientHomePage';
import { getLocalStorageItem } from '../../global/storage';

export default function HomePage() {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo?.user;
    

    if(user.isCoach === true){
        return(
            <CoachesHomePage user={user} />
        )
    }

    if(!userInfo) {
        alert('Du er ikke logget inn! Omdirigerer')
        window.location.href="/login";
    }

    return(
        <div>
            {/* <HomePageCards
                user={user} /> */}
            <ClientHomePage />
        </div>
    )
}