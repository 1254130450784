import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalStorageItem, removeLocalStorageItem } from '../components/global/storage';

const ProtectedRoute = ({ children }) => {
  const userInfo = getLocalStorageItem('limitless-user');

  if (!userInfo) {
    // Brukeren er ikke logget inn
    return <Navigate to="/login" replace />;
  }

  if((userInfo.user.isCoach === true && !userInfo.user.coachID)) {
    alert('Din bruker er ikke godkjent av en Admin.')
    removeLocalStorageItem('limitless-user');
    removeLocalStorageItem('limitless-jwt');
    window.location.href="/login";
  }

  // Brukeren er logget inn
  return children;
};

export default ProtectedRoute;