import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styles from './confirm.module.css';
import { API_BASE_URL } from '../../../constants';

const ConfirmEmail = () => {
  const [message, setMessage] = useState('Bekrefter din e-post...');
  const location = useLocation();

  // Hent token fra URL-en
  const query = new URLSearchParams(location.search);
  const token = query.get('confirmation');

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/email-confirmation`, {
          params: {
            confirmation: token,
          },
        });

        if (response.data) {
          setMessage('Din e-postadresse er bekreftet! Du vil bli omdirigert til innloggingssiden.');
          setTimeout(() => {
            window.location.href="/";
          }, 3000); // Omdiriger etter 3 sekunder
        }
      } catch (error) {
        setMessage('Bekreftelse mislyktes. Vennligst prøv igjen eller kontakt support.');
      }
    };

    if (token) {
      confirmEmail();
    } else {
      setMessage('Bekreftelseslenken er ugyldig.');
    }
  }, [token]);

  return (
    <div className={styles.container}>
      <h1>{message}</h1>
    </div>
  );
};

export default ConfirmEmail;