// import React, { useState, useEffect } from 'react';
// import styles from './register.module.css';
// import axios from 'axios';
// import { API_BASE_URL } from '../../../../constants';
// import { Plugins } from '@capacitor/core';

// const { IAPPlugin } = Plugins;

// export default function RegisterForm() {

//     const [program, setProgram] = useState('');
//     const [coaches, setCoaches] = useState([]);
//     const [selectedCoach, setSelectedCoach] = useState('');
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');
//     const [password, setPassword] = useState('');

//     useEffect(() => {
//         async function getCoaches() {
//             try {
//                 const response = await axios.get(`${API_BASE_URL}/coaches?populate=*`);
//                 console.log(response.data.data);
//                 setCoaches(response.data.data);
//             } catch (error) {
//                 console.error(error);
//             }
//         }
//         getCoaches();
//     }, []);

//     const handleProgramChange = (event) => {
//         setProgram(event.target.value);
//     };

//     const handleCoachChange = (event) => {
//         setSelectedCoach(event.target.value);
//     };

//     const handleNameChange = (event) => {
//         setName(event.target.value);
//     };

//     const handleEmailChange = (event) => {
//         setEmail(event.target.value);
//     };

//     const handlePasswordChange = (event) => {
//         setPassword(event.target.value);
//     }

//     const handlePurchase = async (event) => {
//         event.preventDefault();
//         if (!name || !email || !selectedCoach || !password) {
//             setErrorMessage('Vennligst fyll inn alle felt.');
//             return;
//         }
    
//         try {
//             const productId = 'grunnpakke_1';
//             const purchaseResult = await IAPPlugin.purchaseProduct({ productId });
//             console.log('Purchase successful:', purchaseResult);
    
//             const receiptData = purchaseResult.receipt; // Mottatt kvittering fra kjøpet
    
//             // Send kvitteringen og brukerdataene til backend for validering og registrering
//             const response = await axios.post(`${API_BASE_URL}/transactions/validate-and-register`, {
//                 receiptData,
//                 username: name,
//                 email,
//                 password,
//                 coach: selectedCoach,
//             });
    
//             if (response.data.error) {
//                 setErrorMessage('Kjøpet kunne ikke valideres. Vennligst prøv igjen.');
//             } else {
//                 console.log('User registered successfully:', response.data.user);
//                 alert('Registrering vellykket! Du har nå mottatt en e-post med en link du må bruke for å bekrefte e-postadressen din!');
//                 window.location.href="/";
//             }
//         } catch (error) {
//             console.error('Purchase failed:', error);
//             setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
//         }
//     };

//     // const registerUser = async () => {
//     //     try {
//     //         const response = await axios.post(`${API_BASE_URL}/auth/local/register`, {
//     //             username: name,
//     //             email,
//     //             password,
//     //             coach: selectedCoach,
//     //             confirmed: true,
//     //         });

//     //         console.log('User registered successfully:', response.data);
//     //         alert('Registrering vellykket! Du har nå mottatt en e-post med en link du må bruke for å bekrefte e-postadressen din!')
//     //         window.location.href="/";
//     //     } catch (error) {
//     //         console.error('Registration failed:', error);
//     //         setErrorMessage('Registrering mislyktes. Vennligst prøv igjen.');
//     //     }
//     // };

//     return (
//         <div className={styles.container}>
//             <div className={styles.subH1}>
//                 Registrer deg!
//             </div>
//             <form className={styles.form} onSubmit={handlePurchase}>
//                 <div className={styles.inputContainer}>
//                     <label className={styles.label}>Brukernavn:</label>
//                     <input
//                         type="text"
//                         value={name}
//                         onChange={handleNameChange}
//                         className={styles.input}
//                         placeholder="Fyll inn ditt navn"
//                         required
//                     />
//                 </div>
//                 <div className={styles.inputContainer}>
//                     <label className={styles.label}>E-post:</label>
//                     <input
//                         type="email"
//                         value={email}
//                         onChange={handleEmailChange}
//                         className={styles.input}
//                         placeholder="Fyll inn din e-postadresse"
//                         required
//                     />
//                 </div>
//                 <div className={styles.inputContainer}>
//                     <label className={styles.label}>Passord:</label>
//                     <input
//                         type="password"
//                         value={password}
//                         onChange={handlePasswordChange}
//                         className={styles.input}
//                         placeholder="Fyll inn ditt passord"
//                         required
//                     />
//                 </div>
//                 <div className={styles.dropdownContainer}>
//                     <label className={styles.label}>Velg program:</label>
//                     <select value={program} onChange={handleProgramChange} className={styles.select} required>
//                         <option value="Grunnpakke coaching">Grunnpakke coaching</option>
//                     </select>
//                 </div>
//                 <div className={styles.dropdownContainer}>
//                     <label className={styles.label}>Velg coach:</label>
//                     <select value={selectedCoach} onChange={handleCoachChange} className={styles.select} required>
//                         <option value="">Velg en coach</option>
//                         {coaches.map((coach) => (
//                             <option key={coach.id} value={coach.id}>
//                                 {coach?.attributes?.user?.data?.attributes?.username}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//                 {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
//                 <button type="submit" className={styles.button}>
//                     Registrer!
//                 </button>
//             </form>
//         </div>
//     );
// }

// import React, { useState, useEffect } from 'react';
// import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
// import axios from 'axios';
// import { API_BASE_URL } from '../../../../constants';
// import styles from './register.module.css';

// const ProductId = 'grunnpakke_1';

// const RegisterForm = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [selectedCoach, setSelectedCoach] = useState('');
//   const [program, setProgram] = useState('');
//   const [coaches, setCoaches] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');

//   // Hent liste over coaches
//   useEffect(() => {
//     async function getCoaches() {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/coaches?populate=*`);
//         setCoaches(response.data.data);
//       } catch (error) {
//         console.error('Failed to fetch coaches:', error);
//       }
//     }
//     getCoaches();

//     // Register IAP product
//     console.log('RegisterForm component mounted');
//     InAppPurchase2.register(ProductId);

//     // Håndter godkjent kjøp
//     InAppPurchase2.when(ProductId).approved((product) => {
//       console.log('Purchase approved:', product);
//       product.finish();
//       handleRegister(product.receipt);
//     });

//     // Håndter kjøpsfeil
//     InAppPurchase2.error((err) => {
//       console.error('IAP Error:', err);
//     });

//     InAppPurchase2.refresh();
//   }, []);

//   // Håndter registrering etter godkjent kjøp
//   const handleRegister = async (receipt) => {
//     try {
//       const response = await axios.post(`${API_BASE_URL}/transactions/validate-and-register`, {
//         receipt,
//         username: name,
//         email,
//         password,
//         coach: selectedCoach,
//       });

//       if (response.data.error) {
//         setErrorMessage('Kjøpet kunne ikke valideres. Vennligst prøv igjen.');
//       } else {
//         alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse.');
//         window.location.href = "/";
//       }
//     } catch (error) {
//       console.error('Registration failed:', error);
//       setErrorMessage('Registrering mislyktes.');
//     }
//   };

//   // Håndter kjøpsprosessen
//   const handlePurchase = (event) => {
//     event.preventDefault();  // Forhindre standard form submission
//     if (!name || !email || !password || !selectedCoach || !program) {
//       setErrorMessage('Vennligst fyll inn alle felt.');
//       return;
//     }

//     // Start kjøpsprosessen
//     InAppPurchase2.order(ProductId).catch((err) => {
//       console.error('Purchase failed:', err);
//       setErrorMessage('Kjøpet mislyktes.');
//     });
//   };

//   return (
//     <div className={styles.container}>
//       <div className={styles.subH1}>Registrer deg!</div>
//       <form className={styles.form} onSubmit={handlePurchase}>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>Brukernavn:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn ditt navn"
//             required
//           />
//         </div>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>E-post:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn din e-postadresse"
//             required
//           />
//         </div>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>Passord:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn ditt passord"
//             required
//           />
//         </div>
//         <div className={styles.dropdownContainer}>
//           <label className={styles.label}>Velg program:</label>
//           <select value={program} onChange={(e) => setProgram(e.target.value)} className={styles.select} required>
//             <option value="Grunnpakke coaching">Grunnpakke coaching</option>
//           </select>
//         </div>
//         <div className={styles.dropdownContainer}>
//           <label className={styles.label}>Velg coach:</label>
//           <select value={selectedCoach} onChange={(e) => setSelectedCoach(e.target.value)} className={styles.select} required>
//             <option value="">Velg en coach</option>
//             {coaches.map((coach) => (
//               <option key={coach.id} value={coach.id}>
//                 {coach?.attributes?.user?.data?.attributes?.username}
//               </option>
//             ))}
//           </select>
//         </div>
//         {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
//         <button type="submit" className={styles.button}>Registrer!</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterForm;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { API_BASE_URL } from '../../../../constants';
// import styles from './register.module.css';
// import { store } from 'cordova-plugin-purchase';

// const ProductId = 'grunnpakke_1'; // Your product ID from App Store Connect

// const RegisterForm = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [selectedCoach, setSelectedCoach] = useState('');
//   const [program, setProgram] = useState('');
//   const [coaches, setCoaches] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');

//   // Hent liste over coaches
//   useEffect(() => {
//     async function getCoaches() {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/coaches?populate=*`);
//         setCoaches(response.data.data);
//       } catch (error) {
//         console.error('Failed to fetch coaches:', error);
//       }
//     }

//     getCoaches();

//     const initializeStore = () => {
//       // Register the product
//       store.register({
//         id: ProductId,
//         type: store.NON_CONSUMABLE, // or store.NON_CONSUMABLE based on your product type
//       });

//       // Listen for product being approved
//       store.when(ProductId).approved((product) => {
//         console.log('Purchase approved:', product);
//         product.finish();
//         handleRegister(product.receipt);
//       });

//       // Handle purchase errors
//       store.error((err) => {
//         console.error('IAP Error:', err);
//         setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
//       });

//       // Refresh the store to load products
//       store.refresh();
//     };

//     document.addEventListener('deviceready', initializeStore);

//     // Clean up the event listener on component unmount
//     return () => {
//       document.removeEventListener('deviceready', initializeStore);
//     };
//   }, []);

//   // Håndter registrering etter godkjent kjøp
//   const handleRegister = async (receipt) => {
//     try {
//       const response = await axios.post(`${API_BASE_URL}/transactions/validate-and-register`, {
//         receipt,
//         username: name,
//         email,
//         password,
//         coach: selectedCoach,
//       });

//       if (response.data.error) {
//         setErrorMessage('Kjøpet kunne ikke valideres. Vennligst prøv igjen.');
//       } else {
//         alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse.');
//         window.location.href = '/';
//       }
//     } catch (error) {
//       console.error('Registration failed:', error);
//       setErrorMessage('Registrering mislyktes.');
//     }
//   };

//   // Håndter kjøpsprosessen
//   const handlePurchase = (event) => {
//     event.preventDefault(); // Forhindre standard form submission
//     if (!name || !email || !password || !selectedCoach || !program) {
//       setErrorMessage('Vennligst fyll inn alle felt.');
//       return;
//     }

//     if (window.store) {
//       // Start kjøpsprosessen
//       store.order(ProductId).catch((err) => {
//         console.error('Purchase failed:', err);
//         setErrorMessage('Kjøpet mislyktes.');
//       });
//     } else {
//       console.error('Store is not initialized');
//       setErrorMessage('Store is not initialized');
//     }
//   };

//   return (
//     <div className={styles.container}>
//       <div className={styles.subH1}>Registrer deg!</div>
//       <form className={styles.form} onSubmit={handlePurchase}>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>Brukernavn:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn ditt navn"
//             required
//           />
//         </div>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>E-post:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn din e-postadresse"
//             required
//           />
//         </div>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>Passord:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn ditt passord"
//             required
//           />
//         </div>
//         <div className={styles.dropdownContainer}>
//           <label className={styles.label}>Velg program:</label>
//           <select value={program} onChange={(e) => setProgram(e.target.value)} className={styles.select} required>
//             <option value="Grunnpakke coaching">Grunnpakke coaching</option>
//           </select>
//         </div>
//         <div className={styles.dropdownContainer}>
//           <label className={styles.label}>Velg coach:</label>
//           <select value={selectedCoach} onChange={(e) => setSelectedCoach(e.target.value)} className={styles.select} required>
//             <option value="">Velg en coach</option>
//             {coaches.map((coach) => (
//               <option key={coach.id} value={coach.id}>
//                 {coach?.attributes?.user?.data?.attributes?.username}
//               </option>
//             ))}
//           </select>
//         </div>
//         {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
//         <button type="submit" className={styles.button}>Registrer!</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterForm;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Capacitor } from '@capacitor/core';
// import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
// import { API_BASE_URL } from '../../../../constants';
// import styles from './register.module.css';
// import Purchases from '@ionic-native/purchases';

// const ProductId = 'grunnpakke_1'; // Ditt produkt-ID fra App Store Connect

// const RegisterForm = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [selectedCoach, setSelectedCoach] = useState('');
//   const [program, setProgram] = useState('');
//   const [coaches, setCoaches] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [loading, setLoading] = useState(false);
//   // Add state to store offerings and selected package
//   const [selectedPackage, setSelectedPackage] = useState(null);


//   useEffect(() => {

//     getCoaches();

//     if (Capacitor.isNativePlatform()) {
//       // Initialize RevenueCat
//       Purchases.setup('appl_NUzkxeLoyOWNYaYOerdtDfziPNu');
//     }
//   }, []);

//   // useEffect(() => {
//   //   getCoaches();
  
//   //   if (Capacitor.isNativePlatform()) {
//   //     try {
//   //       initializeInAppPurchases();
//   //     } catch (error) {
//   //       console.error('Error initializing In-App Purchases:', error);
//   //     }
//   //   }
//   //   //eslint-disable-next-line
//   // }, []);

//   // Fetch the list of coaches
//   const getCoaches = async () => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}/coaches?populate=*`);
//       setCoaches(response.data.data);
//     } catch (error) {
//       console.error('Failed to fetch coaches:', error);
//     }
//   };

//   // Initialize In-App Purchases
//   // const initializeInAppPurchases = () => {
//   //   if (!InAppPurchase2) {
//   //     console.error('InAppPurchase2 plugin is not available.');
//   //     return;
//   //   }
  
//   //   // Register the product in InAppPurchase2
//   //   InAppPurchase2.register({
//   //     id: ProductId,
//   //     type: InAppPurchase2.NON_CONSUMABLE,
//   //   });
  
//   //   // Set up the handlers for approved purchase
//   //   InAppPurchase2.when(ProductId).approved((product) => {
//   //     console.log('Purchase approved:', product);
//   //     product.finish(); // Finish the transaction
//   //     handleRegister(product.transaction);
//   //   });
  
//   //   // Set up the error handler for purchases
//   //   InAppPurchase2.when(ProductId).error((err) => {
//   //     console.error('Purchase failed:', err);
//   //     setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
//   //   });
  
//   //   // Refresh the product list to fetch details from the App Store
//   //   InAppPurchase2.refresh();
//   // };

//   const [offerings, setOfferings] = useState(null);

//   // In useEffect or a separate function
//   useEffect(() => {
//     async function fetchOfferings() {
//       try {
//         const offerings = await Purchases.getOfferings();
//         if (offerings.current) {
//           setOfferings(offerings);
//           console.log('Offerings:', offerings);
//         } else {
//           console.log('No offerings available');
//         }
//       } catch (error) {
//         console.error('Error fetching offerings:', error);
//       }
//     }

//     if (Capacitor.isNativePlatform()) {
//       fetchOfferings();
//     }
//   }, []);

//   // Initiate the purchase flow
//   // const handlePurchase = async (event) => {
//   //   event.preventDefault();
//   //   if (!name || !email || !password || !selectedCoach || !program) {
//   //     setErrorMessage('Vennligst fyll inn alle felt.');
//   //     return;
//   //   }
  
//   //   setLoading(true);
//   //   setErrorMessage(''); // Reset error message
  
//   //   try {
//   //     if (!InAppPurchase2) {
//   //       throw new Error('InAppPurchase2 plugin is not available.');
//   //     }
  
//   //     InAppPurchase2.order(ProductId)
//   //       .then((product) => {
//   //         console.log('Purchase initiated:', product);
//   //         // The purchase is initiated; approval is handled in the 'approved' event
//   //       })
//   //       .catch((error) => {
//   //         console.error('Purchase failed:', error);
//   //         setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
//   //         setLoading(false);
//   //       });
//   //   } catch (error) {
//   //     console.error('Error initiating purchase:', error);
//   //     setErrorMessage('Noe gikk galt. Vennligst prøv igjen.');
//   //     setLoading(false);
//   //   }
//   // };

//   const handlePurchase = async (event) => {
//     event.preventDefault();
//     if (!name || !email || !password || !selectedCoach) {
//       setErrorMessage('Vennligst fyll inn alle felt.');
//       return;
//     }
  
//     setLoading(true);
//     setErrorMessage('');
  
//     try {
//       if (Capacitor.isNativePlatform()) {
//         if (offerings && offerings.current) {
//           // Assuming your package is the first available package
//           const packageToPurchase = offerings.current.availablePackages[0];
  
//           const purchaseResult = await Purchases.purchasePackage(packageToPurchase);
  
//           console.log('Purchase successful:', purchaseResult);
  
//           // You can use purchaseResult.customerInfo to check entitlements
  
//           // Proceed to register the user in your app
//           handleRegister();
//         } else {
//           setErrorMessage('Ingen tilgjengelige tilbud.');
//         }
//       } else {
//         setErrorMessage('In-App Purchases er ikke tilgjengelig på denne plattformen.');
//       }
//     } catch (error) {
//       if (!error.userCancelled) {
//         console.error('Purchase failed:', error);
//         setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
//       } else {
//         console.log('User cancelled the purchase');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };
  
  

//   // Handle user registration after a successful purchase
//   // const handleRegister = async (transaction) => {
//   //   try {
//   //     // Send the transaction receipt to your backend for validation
//   //     const response = await axios.post(`${API_BASE_URL}/transactions/validate-and-register`, {
//   //       receipt: transaction.receipt,
//   //       username: name,
//   //       email,
//   //       password,
//   //       coach: selectedCoach,
//   //     });
  
//   //     if (response.data.error) {
//   //       setErrorMessage('Kjøpet kunne ikke valideres. Vennligst prøv igjen.');
//   //     } else {
//   //       alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse.');
//   //       window.location.href = '/';
//   //     }
//   //   } catch (error) {
//   //     console.error('Registration failed:', error);
//   //     setErrorMessage('Registrering mislyktes. Vennligst prøv igjen senere.');
//   //   }
//   // };

//   const handleRegister = async () => {
//     try {
//       const response = await axios.post(`${API_BASE_URL}/auth/local/register`, {
//         username: name,
//         email,
//         password,
//         coach: selectedCoach,
//       });
  
//       if (response.data.error) {
//         setErrorMessage('Registrering mislyktes. Vennligst prøv igjen.');
//       } else {
//         alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse.');
//         window.location.href = '/';
//       }
//     } catch (error) {
//       console.error('Registration failed:', error);
//       setErrorMessage('Registrering mislyktes. Vennligst prøv igjen senere.');
//     }
//   };
  

//   return (
//     <div className={styles.container}>
//       <div className={styles.subH1}>Registrer deg!</div>
//       <form className={styles.form} onSubmit={handlePurchase}>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>Brukernavn:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn ditt navn"
//             required
//           />
//         </div>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>E-post:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn din e-postadresse"
//             required
//           />
//         </div>
//         <div className={styles.inputContainer}>
//           <label className={styles.label}>Passord:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             className={styles.input}
//             placeholder="Fyll inn ditt passord"
//             required
//           />
//         </div>
//         <div className={styles.dropdownContainer}>
//           <label className={styles.label}>Velg program:</label>
//           <select value={program} onChange={(e) => setProgram(e.target.value)} className={styles.select} required>
//             <option value="grunnpakke_1">Grunnpakke coaching</option>
//           </select>
//         </div>
//         <div className={styles.dropdownContainer}>
//           <label className={styles.label}>Velg coach:</label>
//           <select value={selectedCoach} onChange={(e) => setSelectedCoach(e.target.value)} className={styles.select} required>
//             <option value="">Velg en coach</option>
//             {coaches.map((coach) => (
//               <option key={coach.id} value={coach.id}>
//                 {coach?.attributes?.user?.data?.attributes?.username}
//               </option>
//             ))}
//           </select>
//         </div>
//         {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
//         <button type="submit" className={styles.button} disabled={loading}>
//           {loading ? 'Behandler...' : 'Registrer!'}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default RegisterForm;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { API_BASE_URL } from '../../../../constants';
import styles from './register.module.css';
import { v4 as uuidv4 } from 'uuid';


const RegisterForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedCoach, setSelectedCoach] = useState('');
  const [coaches, setCoaches] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [offerings, setOfferings] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [availablePackages, setAvailablePackages] = useState([]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      initializePurchases();
    }
    getCoaches();
    //eslint-disable-next-line
  }, []);
  
  const initializePurchases = async () => {
    try {
      await Purchases.configure({ apiKey: 'appl_NUzkxeLoyOWNYaYOerdtDfziPNu' });
      await fetchOfferings();
    } catch (error) {
      console.error('Error initializing Purchases:', error);
    }
  };

  const getCoaches = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/coaches?populate=*`);
      setCoaches(response.data.data);
    } catch (error) {
      console.error('Failed to fetch coaches:', error);
    }
  };

  const fetchOfferings = async () => {
    try {
      const offeringsResponse = await Purchases.getOfferings();
      console.log('Offerings response:', offeringsResponse);
  
      let allPackages = [];
      const allOfferings = offeringsResponse.all;
  
      for (const offeringKey in allOfferings) {
        const offering = allOfferings[offeringKey];
        if (offering.availablePackages.length > 0) {
          allPackages = allPackages.concat(offering.availablePackages);
        }
      }
  
      if (allPackages.length > 0) {
        setAvailablePackages(allPackages);
        console.log('Available packages:', allPackages);
      } else {
        console.log('Ingen tilgjengelige packages.');
        setErrorMessage('Ingen tilgjengelige produkter for kjøp.');
      }
  
      // **Add this line to set the offerings state variable**
      setOfferings(offeringsResponse);
    } catch (error) {
      console.error('Error fetching offerings:', error);
      setErrorMessage('Feil ved henting av produkter.');
    }
  };
  
  
  

  // const handlePurchase = async (event) => {
  //   event.preventDefault();
  //   if (!name || !email || !password || !selectedCoach || !selectedPackage) {
  //     setErrorMessage('Vennligst fyll inn alle felt.');
  //     return;
  //   }

  //   setLoading(true);
  //   setErrorMessage('');

  //   try {
  //     if (Capacitor.isNativePlatform()) {
  //       const { purchaserInfo } = await Purchases.purchasePackage({ identifier: selectedPackage.identifier, offeringIdentifier: offerings.current.identifier });
  //       console.log('Purchase successful:', purchaserInfo);

  //       // Proceed to register the user
  //       handleRegister();
  //     } else {
  //       setErrorMessage('In-App Purchases er ikke tilgjengelig på denne plattformen.');
  //     }
  //   } catch (error) {
  //     if (error.code !== 'PURCHASES_ERROR_USER_CANCELLED') {
  //       console.error('Purchase failed:', error);
  //       setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
  //     } else {
  //       console.log('User cancelled the purchase');
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handlePurchase = async (event) => {
    event.preventDefault();
    if (!name || !email || !password || !selectedCoach || !selectedPackage) {
      setErrorMessage('Vennligst fyll inn alle felt.');
      return;
    }
  
    setLoading(true);
    setErrorMessage('');
  
    try {
      if (Capacitor.isNativePlatform()) {
        // Generer revenueCatUserID før kjøpet
        const revenueCatUserID = uuidv4();
        console.log('RC_U_ID', revenueCatUserID);
  
        // Identifiser brukeren til RevenueCat
        // let customerInfo;
        // let created;
        try {
          //eslint-disable-next-line
          const { customerInfo, created } = await Purchases.logIn({ appUserID: revenueCatUserID });
          console.log('Logged in to RevenueCat:', customerInfo);
        } catch (error) {
          console.error('Failed to log in to RevenueCat:', error);
          setErrorMessage('Kunne ikke identifisere brukeren. Vennligst prøv igjen.');
          setLoading(false);
          return;
        }
  
        // Foreta kjøpet
        const purchaseResult = await Purchases.purchasePackage({ aPackage: selectedPackage });
        console.log('Purchase successful:', purchaseResult);
  
        // Registrer brukeren og send med revenueCatUserID
        await handleRegister(revenueCatUserID);
      } else {
        setErrorMessage('In-App Purchases er ikke tilgjengelig på denne plattformen.');
      }
    } catch (error) {
      if (error.code !== 'PURCHASES_ERROR_USER_CANCELLED') {
        console.error('Purchase failed:', error);
        setErrorMessage('Kjøpet mislyktes. Vennligst prøv igjen.');
      } else {
        console.log('User cancelled the purchase');
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleRegister = async ( revenueCatUserID ) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/local/register`, {
        username: name,
        email,
        password,
        coach: selectedCoach,
        revenueCat_user_id: revenueCatUserID
      });

      if (response.data.error) {
        setErrorMessage('Registrering mislyktes. Vennligst prøv igjen.');
      } else {
        alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse.');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setErrorMessage('Registrering mislyktes. Vennligst prøv igjen senere.');
    }
  };

  //const availablePackages = offerings?.current?.availablePackages || [];

  return (
    <div className={styles.container}>
      <div className={styles.subH1}>Registrer deg!</div>
      <form className={styles.form} onSubmit={handlePurchase}>
        {/* Form Fields */}
        {/* Name */}
        <div className={styles.inputContainer}>
          <label className={styles.label}>Brukernavn:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn ditt navn"
            required
          />
        </div>
        {/* Email */}
        <div className={styles.inputContainer}>
          <label className={styles.label}>E-post:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn din e-postadresse"
            required
          />
        </div>
        {/* Password */}
        <div className={styles.inputContainer}>
          <label className={styles.label}>Passord:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn ditt passord"
            required
          />
        </div>
        {/* Coach Selection */}
        <div className={styles.dropdownContainer}>
          <label className={styles.label}>Velg coach:</label>
          <select
            value={selectedCoach}
            onChange={(e) => setSelectedCoach(e.target.value)}
            className={styles.select}
            required
          >
            <option value="">Velg en coach</option>
            {coaches.map((coach) => (
              <option key={coach.id} value={coach.id}>
                {coach?.attributes?.user?.data?.attributes?.username}
              </option>
            ))}
          </select>
        </div>
        {/* Produktvalg */}
          {availablePackages.length > 0 ? (
            <div className={styles.dropdownContainer}>
              <label className={styles.label}>Velg produkt:</label>
              <select
                value={selectedPackage?.identifier || ''}
                onChange={(e) => {
                  const selected = availablePackages.find(
                    (pkg) => pkg.identifier === e.target.value
                  );
                  setSelectedPackage(selected);
                }}
                className={styles.select}
                required
              >
                <option value="">Velg et produkt</option>
                {availablePackages.map((pkg) => (
                  <option key={pkg.identifier} value={pkg.identifier}>
                    {pkg.product.title} - {pkg.product.priceString}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <p>Laster produkter...</p>
          )}
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        <button type="submit" className={styles.button} disabled={loading}>
          {loading ? 'Behandler...' : 'Registrer!'}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;

