import React, {useState} from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../constants';
import loginFcss from './loginForm.module.css';
import { setLocalStorageItem } from '../../storage';
import CustomAlert from '../../customAlert';

export default function LoginForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formData, setFormData] = useState({
        identifier: '',
        password: ''
    });
    const [alertData, setAlertData] = useState({
        show: false,
        content: '',
        isError: false,
    });
    const [success, setSuccess] = useState(false);

    const redirectUser = () => {
        if(success)
        window.location.href='/home';
    }

    const redirectToPassword = () => {
        window.location.href="/forgotPassword";
    }

    const hanldeAlertClose = () => {
        setAlertData({
            show: false,
            content: '',
            isError: false,
        })
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);
    //     setIsError(false);
    //     try{
    //         const response = await axios.post(`${API_BASE_URL}/auth/local`, formData);
    //         console.log(response.data);
    //         console.log(response);
    //         const {jwt, ...user} = response.data;
    //         setLocalStorageItem('limitless-jwt', jwt);
    //         setLocalStorageItem('limitless-user', user);
    //         if(response.status === 200) {
    //             setAlertData({
    //                 show:true,
    //                 content: 'Du er nå logget inn! Velkommen!',
    //                 isError: false,
    //             });
    //         } else {
    //             setAlertData({
    //                 show: true,
    //                 content: 'Ops! Noe gikk galt ved innlogg, vennligst prøv igjen eller trykk på linken under for å tilbakestille passord!',
    //                 isError: false,
    //             });
    //         }
    //     } catch(error) {
    //         console.log(error);
    //         setIsError(true);
    //     }
    //     setIsLoading(false);
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsError(false);
        try {
            const response = await axios.post(`${API_BASE_URL}/auth/local`, formData);
            console.log(response.data);
            console.log(response);
            const { jwt, ...user } = response.data;
            setLocalStorageItem('limitless-jwt', jwt);
            setLocalStorageItem('limitless-user', user);
            if (response.status === 200) {
                setSuccess(true);
                setAlertData({
                    show: true,
                    content: 'Du er nå logget inn! Velkommen!',
                    isError: false,
                });
            } else {
                setAlertData({
                    show: true,
                    content: 'Ops! Noe gikk galt ved innlogg, vennligst prøv igjen eller trykk på linken under for å tilbakestille passord!',
                    isError: true,
                });
            }
        } catch (error) {
            console.log(error);
            setIsError(true);
    
            // Sett alertData her for å vise feilmelding ved feil passord
            setAlertData({
                show: true,
                content: 'Feil passord eller brukernavn. Vennligst prøv igjen, eller trykk på "glemt passord" under for å tilbakestille ditt passord!',
                isError: true,
            });
        }
        setIsLoading(false);
    };


    return(
        <div className={loginFcss.container}>
            <form className={loginFcss.form} onSubmit={handleSubmit}>
                <input
                    className={loginFcss.input}
                    type="email"
                    name="identifier"
                    placeholder="Email"
                    value={formData.identifier}
                    onChange={handleChange}
                    required
                    />

                <input
                    className={loginFcss.input}
                    type="password"
                    name="password"
                    placeholder="Passord"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
                <button className={loginFcss.button} type="submit" disabled={isLoading}>
                    {isLoading ? 'Logger inn..' : 'Logg in'}
                </button>
                {isError && <div className={loginFcss.error}>
                    <div className={loginFcss.link} onClick={redirectToPassword}>Glemt passord?</div>
                    </div>}
            </form>
            <div className={loginFcss.alertContainer}>
                <CustomAlert 
                    show={alertData.show}
                    handleClose={hanldeAlertClose}
                    content={alertData.content}
                    isError={alertData.isError}
                    redirectUser={redirectUser}
                    buttonLabel='Jeg forstår!'
                />
            </div>
        </div>
    )
}