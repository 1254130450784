import React, { useState } from 'react';
import axios from 'axios';
import styles from './forgot.module.css';
import { API_BASE_URL } from '../../../constants';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/auth/forgot-password`, {
        email,
      });

      console.log(response);

      alert('En e-post for tilbakestilling av passord er sendt.');
    } catch (error) {
      setMessage('Noe gikk galt. Vennligst prøv igjen.');
      console.error('Error triggering password reset:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Tilbakestill passord</h1>
      {message && <p>{message}</p>}
      <form className={styles.form} onSubmit={handleSubmit}>
            <label>
                E-post:
            </label>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
            />
        <button className={styles.button} type="submit">Send tilbakestillingslenke</button>
      </form>
    </div>
  );
};

export default ForgotPassword;