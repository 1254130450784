import React, {useEffect, useState} from 'react';
import styles from './register.module.css';
import RegisterForm from '../../global/forms/register';
import Logo from '../../../images/logo.png';
import Image from 'react-bootstrap/Image';
import fetchAndLogProducts from '../../../revenueCat/fetchProducts';
import { Capacitor } from '@capacitor/core';

export default function RegisterPage(){
    
    const [products, setProducts] = useState(null);

    useEffect(() => {
        // Sjekk om brukeren bruker web eller app
        if (!Capacitor.isNativePlatform()) {
            window.location.href="/web/register";
        } else {
            // Hvis brukeren er på en native plattform (mobil app), hent RevenueCat-produkter
            async function retrieveRCproducts() {
                try {
                    const response = await fetchAndLogProducts();
                    console.log(response);
                    setProducts(response.data);
                    console.log('products', products);
                } catch (error) {
                    console.error('Failed to fetch RevenueCat products:', error);
                }
            }
            retrieveRCproducts();
        }
        //eslint-disable-next-line
    }, []);

    return(
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <Image fluid alt="Limitless Logo" src={Logo} />
            </div>
            <div className={styles.container2}>
                <RegisterForm />
            </div>
        </div>
    )
}