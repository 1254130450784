import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../constants';

export function useExercises() {
    const [exercises, setExercises] = useState([]);

    useEffect(() => {
        async function fetchExercises() {
            try {
                const getExercisesURL = `${API_BASE_URL}/exercises?sort=name&populate=*`;
                const response = await fetch(getExercisesURL);
                const result = await response.json();
                setExercises(result.data);
            } catch (error) {
                console.error('Error fetching exercises:', error);
                // Handle feil, for eksempel vise en feilmelding til brukeren
            }
        }

        fetchExercises();

        // Returner en funksjon fra useEffect for å rydde opp (clean up)
        return () => {
            // Her kan du legge til ryddekode hvis nødvendig
        };
    }, []); // Tomt avhengighetsarray betyr at effekten kun skal kjøres én gang ved montering

    return exercises;
}