import React from 'react';
import styles from './styles.module.css';
import { getLocalStorageItem } from '../../global/storage';
import MealPageLayoutClientSide from '../../layouts/meals/clientSide';
import MealPageLayoutCoachSide from '../../layouts/meals/coachSide';

export default function MealsPage(){
    const user = getLocalStorageItem('limitless-user');

    const isCoach = user.user.isCoach;
    console.log(user);

    if(!isCoach) {
        return(
            <MealPageLayoutClientSide />
        )
    };

    return(
        <div className={styles.container}>
            <MealPageLayoutCoachSide />
        </div>
    )
}