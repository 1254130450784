import React, { useState, useEffect } from 'react';
import sortCSS from './sort.module.css';
import { SearchableUserDropdown } from '../searchableDropdown';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL } from '../../../constants';
import CustomAlert from '../customAlert';

const UserSort = ({ selectedUsers, allUsers, programId }) => {

    const [assignedUsers, setAssignedUsers] = useState(selectedUsers);
    const [restOfUsers, setRestOfUsers] = useState(
        allUsers.filter(user => !selectedUsers.some(selected => selected.id === user.id))
    );
    const [userIds, setUserIds] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    console.log('assigned users', assignedUsers);
    console.log('the rest', restOfUsers)
    console.log(programId);

    useEffect(() => {
        // Filtrer ut brukere som allerede er i selectedUsers
        const filteredUsers = allUsers.filter(
            user => !selectedUsers.some(selectedUser => selectedUser.id === user.id)
        );
        setRestOfUsers(filteredUsers);
    }, [allUsers, selectedUsers]);

    const handleUserChange = selectedOption => {
        if (selectedOption) {
            setUserIds([...userIds, selectedOption.id]);
            setAssignedUsers([...assignedUsers, selectedOption]);
            setRestOfUsers(restOfUsers.filter(user => user.id !== selectedOption.id));
        }
    };

    const handleRemoveAssignedUser = (userId) => {
        const userToRemove = assignedUsers.find(user => user.id === userId);
        setAssignedUsers(assignedUsers.filter(user => user.id !== userId));
        setRestOfUsers([...restOfUsers, userToRemove]);
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosAuthFetch(`${API_BASE_URL}/programs/${programId}`, {
                data: {
                    users: assignedUsers.map(user => user.id)
                }
            }, 'PUT');
            console.log(response.data);
            setShowAlert(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        window.location.reload();
    }

    return ( 
        <div>
            <CustomAlert 
                show={showAlert}
                handleClose={handleAlertClose}
                buttonLabel="Den er grei!"
                content="Klientene er lagt til!"
                title="Suksess!"
            />
            <div>
                <h3>Klienter på dette programmet</h3>
                <ul className={sortCSS.list}>
                    {assignedUsers.map(user => (
                        <li className={sortCSS.selectedClient} key={user.id}>
                            {user.attributes.username}
                            <div className={sortCSS.deleteButton} onClick={() => handleRemoveAssignedUser(user.id)}>X</div>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h3>Legg til klienter</h3>
                <SearchableUserDropdown objects={restOfUsers} onSelect={handleUserChange} />
            </div>
            <div className={sortCSS.submitDiv}>
                <div className={sortCSS.submitButton} onClick={handleSubmit}>SEND</div>
            </div>
            
        </div>
    )

}

export default UserSort;