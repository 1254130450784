//import React from 'react';
//import { Routes, Route } from 'react-router-dom';
//import LoginPage from './components/pages/login';
//import Layout from './components/layout';
//import HomePage from './components/pages/home';
//import { getLocalStorageItem } from './components/global/storage';
import TrainingPage from './components/pages/training';
import CreatePage from './components/pages/training/create';
import TemplatePage from './components/pages/training/template';
import NutritionPage from './components/pages/nutrition';
import ClientSideProfilePage from './components/pages/profile/clientSide';
import ClientSideCommunication from './components/global/communication/clientSide';
import RegisterPage from './components/pages/register';
import ResetPassword from './components/pages/resetPassword';
import ConfirmEmail from './components/pages/confirmEmail';
import ForgotPassword from './components/pages/forgotPassword';
import RegisterCoachPage from './components/pages/registerCoachPage';
//import CustomAlert from './components/global/customAlert';


// function App() {

//   const userInfo = getLocalStorageItem('user');

//   return (
//     <Routes>
//       <Route index element={<LoginPage />}/>
//       <Route path="/register" element={<RegisterPage />}/>
//       <Route path="/login" element={<LoginPage />}/>
//       <Route path="/resetPassword" element={<ResetPassword />}/>
//       <Route path="/confirmEmail" element={<ConfirmEmail />}/>
//       <Route path="/forgotPassword" element={<ForgotPassword />}/>
//       {userInfo && (
//         <Route path="/" element={<Layout />}>
//           <Route path="/home" element={<HomePage user={userInfo.user} />}/>
//           <Route path="/training" element={<TrainingPage user={userInfo.user} />}/>
//           <Route path="/training/create" element={<CreatePage user={userInfo.user} />}/>
//           <Route path="/training/template" element={<TemplatePage user={userInfo.user} />}/>
//           <Route path="/nutrition" element={<NutritionPage user={userInfo.user} />}/>
//           <Route path="/clients" element={<ClientsPage user={userInfo.user} />}/>
//           <Route path="/profile" element={<ClientSideProfilePage user={userInfo.user}/>}/>
//           <Route path="/chat" element={<ClientSideCommunication user={userInfo.user}/>}/>
//         </Route>
//       )}
//     </Routes>
//   );
// }

// export default App;


import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Purchases } from '@revenuecat/purchases-capacitor';
import LoginPage from './components/pages/login';
import Layout from './components/layout';
import HomePage from './components/pages/home';
import { getLocalStorageItem } from './components/global/storage';
import CommunicationPage from './components/pages/communication';
import CoachesClientPage from './components/pages/clients';
import ProtectedRoute from './helpers/protectedRoutes';
import RegisterWeb from './components/global/forms/registerWeb';
import MealsPage from './components/pages/meals';

function RevenueCatWrapper({ children }) {
  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    const getCustomerInfo = async () => {
      try {
        const info = await Purchases.getCustomerInfo();
        setCustomerInfo(info);
      } catch (error) {
        console.error('Error fetching customer info:', error);
      }
    };

    getCustomerInfo();
  }, []);

  // You can add purchase handling functions here

  return React.Children.map(children, child =>
    React.cloneElement(child, { customerInfo })
  );
}

function App() {
  const userInfo = getLocalStorageItem('limitless-user');
  console.log('FRA APP', userInfo);

  return (
    <RevenueCatWrapper>
      <Routes>
        <Route index element={<LoginPage />}/>
        <Route path="/register" element={<RegisterPage />}/>
        <Route path="/web/register" element={<RegisterWeb />}/>
        <Route path="/register/o0i9u8y7t6r5e4w3q2/coach" element={<RegisterCoachPage />}/>
        <Route path="/login" element={<LoginPage />}/>
        <Route path="/resetPassword" element={<ResetPassword />}/>
        <Route path="/confirmEmail" element={<ConfirmEmail />}/>
        <Route path="/forgotPassword" element={<ForgotPassword />}/>
          <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="home" element={<HomePage />}/>
            <Route path="training" element={<TrainingPage />}/>
            <Route path="training/create" element={<CreatePage />}/>
            <Route path="training/template" element={<TemplatePage />}/>
            <Route path="nutrition" element={<NutritionPage />}/>
            <Route path="communication" element={<CommunicationPage />}/>
            <Route path="clients" element={<CoachesClientPage />}/>
            <Route path="profile" element={<ClientSideProfilePage />}/>
            <Route path="chat" element={<ClientSideCommunication />}/>
            <Route path="meals" element={<MealsPage />}/>
          </Route>
      </Routes>
    </RevenueCatWrapper>
  );
}

export default App;