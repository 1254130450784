import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import hCSS from './header.module.css';
import Logo from '../../images/logo.png';
import Settings from '../../images/icons/setting.png';
import Logout from '../../images/icons/logout.png';
import CustomAlert from '../global/customAlert';
import { getLocalStorageItem, removeLocalStorageItem } from '../global/storage';
import { axiosAuthFetch } from '../../helpers/axios';
import { API_BASE_URL } from '../../constants';
import Loader from '../global/loader';

export default function Header() {

    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;
    const [isLoading, setIsLoading] = useState(false);
    const [showDangerZone, setShowDangerZone] = useState(false);

    const [alertData, setAlertData] = useState({
        show:false,
        content: '',
        isError: false
    })

    const onLogoutClick = () => {
        setAlertData({
            show:true,
            content: 'Er du sikker på at du vil logge ut?',
            isError:false,
        })
    }

    const redirectUser = () => {
        removeLocalStorageItem('limitless-jwt');
        removeLocalStorageItem('limitless-user');
        window.location.href="/";
    }

    const handleAlertClose = () => {
        setAlertData({
            show: false,
            content:'',
            isError: false
        })
    }

    const toggleDangerZone = () => setShowDangerZone(!showDangerZone);

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm(
          'Er du sikker på at du vil slette kontoen din? Denne handlingen kan ikke angres.'
        );
        
        if (confirmDelete) {
          try {
            setIsLoading(true);
            const response = await axiosAuthFetch(`${API_BASE_URL}/users/${user.id}`, {}, 'DELETE');
            console.log('Account deleted:', response);
            removeLocalStorageItem('limitless-user');
            removeLocalStorageItem('limitless-jwt');
            // Logg ut brukeren
            // For eksempel: logoutUser();
            setIsLoading(false);
            alert('Kontoen din er slettet.');
            // Omdiriger brukeren
            window.location.href = "/login";
          } catch (error) {
            console.error('Error deleting account:', error);
            setIsLoading(false);
            alert('Kunne ikke slette kontoen. Vennligst prøv igjen senere.');
          }
        }
      };

      if(isLoading) {
        return(<Loader />)
      }
    return(
        <div className={hCSS.container}>
            <div className={hCSS.child1} onClick={toggleDangerZone}>
                <Image src={Settings} fluid alt="settings-icon" />
            </div>
            <div className={hCSS.imageContainer}>
                <Image src={Logo} fluid alt="Limitless coaching logo" />
            </div>
            <div className={hCSS.header}>LIMITLESS COACHING PLATFORM</div>
            <div className={hCSS.child2} onClick={onLogoutClick}>
                <Image src={Logout} fluid alt="log out icon" />
            </div>
            {showDangerZone && (
                <div className={hCSS.dropdown}>
                    <h4>DANGER ZONE</h4>
                    <div className={hCSS.deleteButton} onClick={handleDeleteAccount}>Slett konto</div>
                </div>
            )}
            <div>
                <CustomAlert 
                    show={alertData.show}
                    handleClose={handleAlertClose}
                    content={alertData.content}
                    isError={alertData.error}
                    redirectUser={redirectUser}
                    buttonLabel='Ja'
                    declineButton='Nei'
                />
            </div>
        </div>
    )
}