// import React, { useEffect, useState } from 'react';
// import styles from './styles.module.css';

// export default function WeeklyCalendar({ workouts }) {
//     const [weekDays, setWeekDays] = useState([]);

//     useEffect(() => {
//         // Calculate the current week (starting on Monday)
//         const currentDate = new Date();
//         const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)); // Monday
//         const days = [];

//         for (let i = 0; i < 7; i++) {
//             const day = new Date(firstDayOfWeek);
//             day.setDate(firstDayOfWeek.getDate() + i);
//             days.push(day);
//         }

//         setWeekDays(days);
//     }, []);

//     const isWorkoutDay = (date) => {
//         return workouts.some(workout => {
//             const workoutDate = new Date(workout.attributes.date_performed);
            
//             return workoutDate.toDateString() === date.toDateString();
//         });
//     };

//     return (
//         <div className={styles.calendarContainer}>
//             {weekDays.map((day, index) => {
//                 const dayName = day.toLocaleDateString('no-NO', { weekday: 'short' });
//                 const date = day.getDate();
//                 const hasWorkedOut = isWorkoutDay(day);

//                 return (
//                     <div key={index} className={styles.dayContainer}>
//                         <div className={styles.dayName}>{dayName}</div>
//                         <div className={hasWorkedOut ? styles.workoutDay : styles.noWorkoutDay}>{date}</div>
//                     </div>
//                 );
//             })}
//         </div>
//     );
// }

import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

export default function WeeklyCalendar({ workouts }) {
    const [weekDays, setWeekDays] = useState([]);
    console.log('workouts', workouts);

    // Helper function to get the start and end of the current week
    const getCurrentWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // Søndag = 0, Mandag = 1, osv.
        
        // Sett startdato til mandag
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)); // Mandag start
        
        // Generer alle ukedager fra mandag til søndag
        const days = [];
        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeek);
            day.setDate(startOfWeek.getDate() + i);
            days.push(day);
        }

        return days;
    };

    // Helper function to compare if two dates are the same day
    const isSameDay = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };

    // Function to check if a given date has a workout
    const isWorkoutDay = (date) => {
        return workouts.some(workout => {
            const workoutDate = new Date(workout.attributes.date_performed);
            return isSameDay(workoutDate, date); // Bruk isSameDay for å sammenligne kun datoen
        });
    };

    useEffect(() => {
        const daysOfWeek = getCurrentWeek();
        setWeekDays(daysOfWeek);
    }, []);

    return (
        <div className={styles.calendarContainer}>
            {weekDays.map((day, index) => {
                const dayName = day.toLocaleDateString('no-NO', { weekday: 'short' });
                const date = day.getDate();
                const hasWorkedOut = isWorkoutDay(day);

                return (
                    <div key={index} className={styles.dayContainer}>
                        <div className={styles.dayName}>{dayName}</div>
                        <div className={hasWorkedOut ? styles.workoutDay : styles.noWorkoutDay}>{date}</div>
                    </div>
                );
            })}
        </div>
    );
}