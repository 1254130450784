import React from 'react';
import loaderCSS from './loader.module.css';

const Loader = () => {
  return (
    <div className={loaderCSS.loaderContainer}>
      <div className={loaderCSS.loader}></div>
    </div>
  );
};

export default Loader;