import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../constants';
import styles from './register.module.css';


const RegisterWeb = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedCoach, setSelectedCoach] = useState('');
  const [coaches, setCoaches] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCoaches();
    //eslint-disable-next-line
  }, []);


  const getCoaches = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/coaches?populate=*`);
      setCoaches(response.data.data);
    } catch (error) {
      console.error('Failed to fetch coaches:', error);
    }
  };

  

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/auth/local/register`, {
        username: name,
        email,
        password,
        isCoach: false,
        coach: selectedCoach
      });
      console.log(response);
      if (response.data.error) {
        setErrorMessage('Registrering mislyktes. Vennligst prøv igjen.');
      } else {
        alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse!');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setErrorMessage('Registrering mislyktes. Vennligst prøv igjen senere.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={styles.container}>
      <div className={styles.subH1}>Registrer deg!</div>
      <form className={styles.form} onSubmit={handleRegister}>
        <div className={styles.inputContainer}>
          <label className={styles.label}>Brukernavn:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn ditt navn"
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={styles.label}>E-post:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn din e-postadresse"
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={styles.label}>Passord:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn ditt passord"
            required
          />
        </div>
        <div className={styles.dropdownContainer}>
          <label className={styles.label}>Velg coach:</label>
          <select
            value={selectedCoach}
            onChange={(e) => setSelectedCoach(e.target.value)}
            className={styles.select}
            required
          >
            <option value="">Velg en coach</option>
            {coaches.map((coach) => (
              <option key={coach.id} value={coach.id}>
                {coach?.attributes?.user?.data?.attributes?.username}
              </option>
            ))}
          </select>
        </div>
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        <button type="submit" className={styles.button} disabled={loading}>
          {loading ? 'Behandler...' : 'Registrer!'}
        </button>
      </form>
    </div>
  );
};

export default RegisterWeb;
