import React, { useState, useEffect } from 'react';
import nutriCSS from './nutrition.module.css';
import CustomAlert from '../../global/customAlert';
import { axiosAuthFetch, axiosImageAuthFetch } from '../../../helpers/axios';
import { API_BASE_URL } from '../../../constants';
import Loader from '../../global/loader';
import { API_GRAPHQL } from '../../../constants';
import { SearchableUserDropdown } from '../../global/searchableDropdown';
import { getLocalStorageItem } from '../../global/storage';

const NutritionForm = () => {
    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo?.user;

    const coachId = user.coachID;
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userID, setUserID] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const query = ` 
                query{
                    coaches(filters: {id:{eq:${coachId}}}) {
                    data{
                        id
                        attributes{
                        clients{
                            data{
                            id
                            attributes{
                                username
                            }
                            }
                        }
                        }
                    }
                    }
                }
            `;

    useEffect(() => {
        async function getUsers() {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
                console.log(response.data.coaches.data[0].attributes.clients.data);
                setClients(response.data.coaches.data[0].attributes.clients.data);
                // console.log(users);
            } catch(error) {
                console.error(error)
                setIsError(true)
            } finally {
                setIsLoading(false);
            }
        }
        getUsers();
    }, [query])

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleUserChange = selectedOption => {
        if (selectedOption) {
            setUserID(selectedOption.id);
        } else {
            setUserID(''); // Eller en tom verdi som gir mening for din app
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        window.location.reload();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const testData = {
           "data": {
            "Name": name,
            "coach": coachId,
            "Description": description,
            "user": userID,
           }
        }

        console.log(testData);

        const mealplanResponse = await axiosAuthFetch(`${API_BASE_URL}/matplans`, testData , 'POST');

        const mealplanId = mealplanResponse.data.id;

        const imageData = new FormData();
        imageData.append('ref', 'api::matplan.matplan');
        imageData.append('refId', mealplanId);
        imageData.append('field', 'Matplan');
        imageData.append('files', file);

        const imageResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload`, imageData);
        console.log(imageResponse);
    }

    if(isLoading || !clients) {
        return(<div><Loader /></div>)
    }

    if(isError) {
        return(<div>Oida, her gikk noe galt! Vennligst prøv å last inn siden på nytt!</div>)
    }

    return (
        <div className={nutriCSS.container}>
            <div className={nutriCSS.overskrift}>OPPRETT NY MATPLAN</div>
            <div className={nutriCSS.form}>
                <div className={nutriCSS.user}>Velg klient</div>
                <SearchableUserDropdown objects={clients} onSelect={handleUserChange}/>
                <input className={nutriCSS.input} placeholder="Navn på matplan" onChange={handleNameChange} />
                <input className={nutriCSS.input} placeholder="Beskrivelse" onChange={handleDescriptionChange} />
                <input className={`${nutriCSS.input} ${nutriCSS.fileInput}`} type="file" onChange={handleFileChange} required/>
                <div className={nutriCSS.submit} onClick={handleSubmit}>Send</div>

                <CustomAlert 
                    show={showAlert}
                    handleClose={handleAlertClose}
                    buttonLabel="Den er grei!"
                    content="Matplanen er opprettet!"
                    title="Suksess!"
                />
            </div>
        </div>
    )
    }
  
  export default NutritionForm;