import axios from "axios";
import { getLocalStorageItem } from "../../components/global/storage";

export function headers() {
    const token = getLocalStorageItem('limitless-jwt');
    return {
        'Content-Type' : 'application/json',
        Authorization : `Bearer ${token}`
    }
};

export async function axiosAuthFetch(url, data = null, method = 'get') {

    try {
        const response = await axios({
            method: method,
            url: url,
            headers: headers(),
            data: data
        });
        return response.data;
    } catch(error) {
        if (error.response) {
            console.error('Server Error:', error.response.data);
            // alert(`Kunne ikke opprette måltid. Feil: ${JSON.stringify(error.response.data)}`);
        } else if (error.request) {
            console.error('No response received:', error.request);
            // alert('Ingen respons fra serveren. Vennligst sjekk nettverkstilkoblingen din.');
        } else {
            console.error('Error setting up request:', error.message);
            // alert(`Kunne ikke opprette måltid. Feil: ${error.message}`);
        }
    }
};

export function imageHeaders() {
    const token = getLocalStorageItem('limitless-jwt');
    return {
        'Content-Type' : 'multipart/form-data',
        Authorization : `Bearer ${token}`
    }
};

export async function axiosImageAuthFetch(url, data = null, method = 'post') {

    try {
        const response = await axios({
            method: method,
            url: url,
            headers: imageHeaders(),
            data: data
        });
        return response.data;
    } catch(error) {
        console.error(error);
    }
};