import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../constants';
import styles from './register.module.css';


const RegisterCoachForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);


  
  

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/auth/local/register`, {
        username: name,
        email,
        password,
        isCoach: true,
      });
      console.log(response);
      if (response.data.error) {
        setErrorMessage('Registrering mislyktes. Vennligst prøv igjen.');
      } else {
        alert('Registrering vellykket! Du har mottatt en e-post for bekreftelse, og en admin må godkjenne brukeren din!');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setErrorMessage('Registrering mislyktes. Vennligst prøv igjen senere.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={styles.container}>
      <div className={styles.subH1}>Registrer deg!</div>
      <form className={styles.form} onSubmit={handleRegister}>
        <div className={styles.inputContainer}>
          <label className={styles.label}>Brukernavn:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn ditt navn"
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={styles.label}>E-post:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn din e-postadresse"
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={styles.label}>Passord:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            placeholder="Fyll inn ditt passord"
            required
          />
        </div>
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        <button type="submit" className={styles.button} disabled={loading}>
          {loading ? 'Behandler...' : 'Registrer!'}
        </button>
      </form>
    </div>
  );
};

export default RegisterCoachForm;