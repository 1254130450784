import { useState, useEffect} from 'react';
import { getLocalStorageItem } from '../../components/global/storage';
import { API_GRAPHQL } from '../../constants';
import { axiosAuthFetch } from '../../helpers/axios';

export function useGetProgramsByCoach() {
    const user = getLocalStorageItem('limitless-user');
    const coachID = user.user.coachID;
    const url = API_GRAPHQL;
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        async function getPrograms(url) {

            const query = `query {
                programs(
                    pagination: {limit: 1000},
                    filters: {created_by_coach: {id:{eq:${coachID}}}}
                ) {
                    data{
                        id
                        attributes {
                            programName
                            Description
                            cover_image{
                                data{
                                    id
                                    attributes{
                                        images{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            pairs{
                                data{
                                    id
                                    attributes{
                                        description
                                        comment
                                        exercise{
                                            data{
                                                id
                                                attributes{
                                                    name
                                                    image{
                                                        data{
                                                            id
                                                            attributes{
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            users{
                                data{
                                    id
                                    attributes{
                                        username
                                        profile_picture{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`;
            ;
            try {
                setIsError(false);
                setIsLoading(false);

                const response = await axiosAuthFetch(url, { query }, 'post')
                console.log(response);
                setPrograms(response.data.programs.data);
            } catch(error) {
                console.error(error);
            }
            finally {
                setIsLoading(false);
            }
        }

        getPrograms(url)
    }, [coachID, url])

    return {programs, isLoading, isError};

}