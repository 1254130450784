import React from 'react';
import caCss from './alert.module.css';
import Modal from 'react-bootstrap/Modal';

export default function CustomAlert({ show, buttonLabel, handleClose, content, redirectUser, title, declineButton }) {
    
    const handleButtonClick = () => {
        handleClose();
        if(redirectUser) {
            redirectUser();
        }
    }
    return(
        <Modal className={caCss.container} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer>
                <button className={caCss.button}
                onClick={handleButtonClick}
                >{buttonLabel}</button>
                {declineButton && <button className={caCss.button2} onClick={handleClose}>
                    {declineButton}
                </button>}
            </Modal.Footer>
        </Modal>
    )
}