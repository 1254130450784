import React, {useState, useEffect} from 'react';
//import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';
//import defaultImage from '../../../images/blue_man.png';
import { API_BASE_URL, API_GRAPHQL } from '../../../constants';
import { axiosAuthFetch } from '../../../helpers/axios';
import Loader from '../../global/loader';
import CustomAlertModal from '../../global/customModal';

const renderStars = (rating) => {
    const stars= [];
    for (let i= 1; i <= 5; i++) {
        stars.push(
            <span key={i} className={i <= rating ? styles.starFilled : styles.starEmpty}>&#9733;</span>
        );
    }
    return stars;
}

const CoachSideWorkoutCards = ({ user }) => {
    const userInfo = user.coachID;
    const [showModal, setShowModal] = useState(false);
    const [currentWorkout, setCurrentWorkout] = useState(null);
    const [coachComment, setCoachComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [workouts, setWorkouts] = useState([]);

    const query = `query {
                        workoutSessions(filters: { client: { coach: { id: { eq: ${userInfo} } } } }) {
                            data {
                            id
                            attributes {
                                date_performed
                                name
                                clients_comments
                                exercise_logs{
                                    data{
                                        id
                                        attributes{
                                            sett
                                            reps
                                            vekt
                                            order
                                            exercise{
                                                data{
                                                    id
                                                    attributes{
                                                        name
                                                        image{
                                                            data{
                                                                id
                                                                attributes{
                                                                    url
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                program{
                                    data{
                                        id
                                        attributes{
                                        programName
                                            cover_image{
                                                data{
                                                    id
                                                    attributes{
                                                        images{
                                                            data{
                                                                id
                                                                attributes{
                                                                    url
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                client_comment
                                rating
                                coach_comments
                                duration
                                client {
                                data {
                                    id
                                    attributes {
                                    username
                                    profile_picture{
                                        data{
                                            id
                                            attributes{
                                                url
                                            }
                                        }
                                    }
                                    }
                                }
                                }
                            }
                            }
                        }
                    }`

    useEffect(() => {
        async function getWorkouts() {
            try {
                setIsLoading(true);
                const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
                console.log('workouts', response.data);
                setWorkouts(response.data.workoutSessions.data);
            } catch(error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        getWorkouts();
    }, [query]);

    const handleWorkoutClick = (workout) => {
        setCurrentWorkout(workout);
        setCoachComment(workout.attributes.coach_comments || '');
        setShowModal(true);
    }

    const closeModal = () => {
        setCurrentWorkout(null);
        setShowModal(false);
      };

    const handleSaveComment = async () => {
        console.log("Saved comment for check-in ID:", currentWorkout.id, "Comment:", coachComment);
        const url = `${API_BASE_URL}/workout-sessions/${currentWorkout.id}`;
        const response = await axiosAuthFetch(url, {data:{
                coach_comments: coachComment
        }}, 'PUT');
        console.log(response);
        alert('Operasjonen er vellykket!')
        setShowModal(false);
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long' };
        return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
    };

    const groupExerciseLogsByOrder = (exerciseLogs) => {
        const groupedExercises = {};
    
        exerciseLogs.forEach((log) => {
          const exerciseId = log.attributes.exercise.data.id;
          const exerciseName = log.attributes.exercise.data.attributes.name;
          const exerciseImageUrl = log.attributes.exercise.data.attributes.image.data[0]?.attributes?.url || '';
          const order = log.attributes.order;  // Capture the order field
    
          if (!groupedExercises[exerciseId]) {
            groupedExercises[exerciseId] = {
              exerciseName,
              exerciseImageUrl,
              order,  // Store the order with the grouped exercise
              sets: [],
            };
          }
    
          // Push all sets for the same exercise together
          groupedExercises[exerciseId].sets.push({
            sett: log.attributes.sett,
            reps: log.attributes.reps,
            vekt: log.attributes.vekt,
          });
        });
    
        // Sort the exercises by the 'order' field before returning them
        return Object.values(groupedExercises).sort((a, b) => a.order - b.order);
      };

      const calculateTotalWeight = () => {
        if (!currentWorkout) return 0;
    
        return currentWorkout.attributes.exercise_logs.data.reduce((total, log) => {
          const { reps, vekt } = log.attributes;
          return total + reps * vekt;
        }, 0);
      };

    const sortedWorkouts = [...workouts].sort((a, b) => new Date(b.attributes.date_performed) - new Date(a.attributes.date_performed));

    if(isLoading) {
        return(
            <Loader />
        )
    }

    return (
        <div className={styles.container}>
        {sortedWorkouts.length > 0 ? (
            sortedWorkouts.map((workout) => {
                const { client, rating, date_performed } = workout.attributes;
                const imageUrl = workout.attributes.program.data.attributes.cover_image.data.attributes.images.data[0].attributes.url;
                console.log(imageUrl);
                //const clientProfilePicture = client?.data?.attributes?.profile_picture?.data?.attributes?.url || defaultImage;

                return (
                    <div key={workout.id} className={styles.card} style={{ backgroundImage: `url(${imageUrl})`}} onClick={() => handleWorkoutClick(workout)}>
                        {/* <div className={styles.clientWorkoutImage}>
                            <Image className={styles.clientProfilePicture} fluid alt="client-image" src={clientProfilePicture} />
                        </div> */}
                        <div className={styles.cardWorkoutName}>{workout.attributes.name}</div>
                        <div className={styles.cardInfo}>{client.data.attributes.username}</div>
                        <div className={styles.cardInfo}>{formatDate(date_performed)}</div>
                        <div className={styles.cardInfo}>{renderStars(rating)}</div>
                    </div>
                );
            })
        ) : (
            <div className={styles.noWorkoutsMessage}>
                Ingen treningsøkter tilgjengelig.
            </div>
        )}

    {currentWorkout && (   
        <CustomAlertModal
          show={showModal}
          handleClose={closeModal}
          content={
            <div className={styles.modalContent}>
                 <div className={styles.headerContainer} style={{
                  backgroundImage: currentWorkout.attributes.program.data.attributes.cover_image.data &&
                  currentWorkout.attributes.program.data.attributes.cover_image.data.attributes.images.data[0]
                    ? `url(${currentWorkout.attributes.program.data.attributes.cover_image.data.attributes.images.data[0].attributes.url})`
                    : 'none'
                }}>
                    <div className={styles.headerNavn}>{currentWorkout?.attributes?.name}</div>
                    <div className={styles.timerContainer}>
                        <div className={styles.timer}>
                        Tid: {currentWorkout.attributes.duration || 'N/A'}
                        </div>
                    </div>
                </div>
              {groupExerciseLogsByOrder(currentWorkout.attributes.exercise_logs.data).map(
                (exerciseData, index) => (
                  <div key={index} className={styles.exerciseCard}>
                    <div className={styles.cardHeader}>
                      <div
                        className={styles.cardHeaderImageContainer}
                        style={{ backgroundImage: `url(${exerciseData.exerciseImageUrl})` }}
                      />
                      <div className={styles.exerciseNameDiv}>
                        <p className={styles.exerciseName}>{exerciseData.exerciseName}</p>
                      </div>
                    </div>
                    <div className={styles.subHeader}>
                      <div className={styles.subSet}>Sett</div>
                      <div className={styles.subReps}>Reps utført</div>
                      <div className={styles.subReps}>Vekt</div>
                    </div>
                    {exerciseData.sets.map((set, setIndex) => (
                      <div key={setIndex} className={styles.set}>
                        {set.sett}.
                        <div className={styles.presetReps}>{set.reps}</div>
                        <div className={styles.presetReps}>{set.vekt} kg</div>
                      </div>
                    ))}
                  </div>
                )
              )}
              <div className={styles.inputRating}>
                <label>Klients rating av økt:</label>
                {renderStars(currentWorkout.attributes.rating)}
              </div>
              <div className={styles.clientComment}>
                <strong>Klients kommentar: </strong>{currentWorkout.attributes.clients_comments}
              </div>
              <div className={styles.totalWeight}>
                <p>Total vekt løftet: {calculateTotalWeight()} kg</p>
              </div>

              <p className="mt-5"><strong>Din kommentar:</strong></p>
                        <textarea
                            className={styles.coachCommentTextarea}
                            value={coachComment}
                            onChange={(e) => setCoachComment(e.target.value)}
                            placeholder="Skriv din kommentar her"
                            rows="6"
                        />
                        <div className={styles.saveButton} onClick={handleSaveComment}>Lagre</div>
            </div>
          }
          buttonLabel="Lukk"
          submit={closeModal}
          modalTitle="Økt detaljer"
        />
    )}
        </div>
    );
}

export default CoachSideWorkoutCards;