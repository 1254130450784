import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../loader';
import Modal from 'react-bootstrap/Modal';
import { getLocalStorageItem } from '../../storage';

export default function CreateIngredient() {
    const [ingredientName, setIngredientName] = useState('');
    // const [description, setDescription] = useState('');
    const [protein, setProtein] = useState(0);
    const [carbs, setCarbs] = useState(0);
    const [fat, setFat] = useState(0);
    const [kcals, setKcals] = useState(0);
    const [amount, setAmount] = useState(100); // Standard mengde
    const [unit, setUnit] = useState('g'); // Standard enhet
    const [ingredientArray, setIngredientArray] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const userInfo = getLocalStorageItem('limitless-user');
    const isAdmin = userInfo.user.isAdmin;

    useEffect(() => {
        async function getIngredients() {
            try {
                setIsLoading(true);
                const response = await axiosAuthFetch(`${API_BASE_URL}/ingredients?populate=*`);
                setIngredientArray(response.data || []);
                console.log(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        getIngredients();
    }, []);

    const handleSubmit = async () => {
        try {
            const data = {
                name: ingredientName,
                macros: [{
                    protein: parseFloat(normalizeDecimal(protein)),
                    fat: parseFloat(normalizeDecimal(fat)),
                    carbs: parseFloat(normalizeDecimal(carbs)),
                    kcals: parseFloat(normalizeDecimal(kcals))
            }],
                amount: parseFloat(normalizeDecimal(amount)), // Mengde
                unit,   // Enhet
            };

            await axiosAuthFetch(`${API_BASE_URL}/ingredients`, { data }, 'POST');
            alert('Ingrediens lagt til!');
            window.location.reload();
        } catch (error) {
            console.error('Error adding ingredient:', error);
        }
    };

    const normalizeDecimal = (value) => {
        // Omgjør ',' til '.' for desimaler
        return value.toString().replace(',', '.');
    };

    const handleDeleteIngredient = async () => {
        if (window.confirm("Er du sikker på at du vil slette denne ingrediensen?")) {
            try {
                await axiosAuthFetch(`${API_BASE_URL}/ingredients/${selectedIngredient.id}`, {}, 'DELETE');
                alert('Ingrediens slettet!');
                setIngredientArray(prev => prev.filter(ing => ing.id !== selectedIngredient.id));
                handleCloseModal();
            } catch (error) {
                console.error("Error deleting ingredient:", error);
                alert('Kunne ikke slette ingrediens. Vennligst prøv igjen.');
            }
        }
    };

    const handleIngredientClick = (ingredient) => {
        setSelectedIngredient(ingredient);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedIngredient(null);
    };

    const filteredIngredients = searchText
    ? ingredientArray.filter((ingredient) =>
        ingredient.attributes?.name?.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];

    if(isLoading) {
        return(<Loader />)
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className="text-center">Ingredienser</h2>
                <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Søk etter ingrediens"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText && ingredientArray.length > 0 && (
                    <div className={styles.ingredientList}>
                        {filteredIngredients.map((ingredient) => (
                            <div 
                                key={ingredient.id} 
                                onClick={() => handleIngredientClick(ingredient)}
                                className={styles.ingredientItem}
                            >
                                {ingredient.attributes.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                <label className={styles.label}>
                    Navn:
                    <input
                        className={styles.input}
                        value={ingredientName}
                        placeholder="Navn"
                        onChange={(e) => setIngredientName(e.target.value)}
                    />
                </label>
                <label className={styles.label}>
                    Protein:
                    <input
                        className={styles.input}
                        type="number"
                        value={protein}
                        placeholder="Protein"
                        onChange={(e) => setProtein(e.target.value)}
                    />
                </label>
                <label className={styles.label}>
                    Karbohydrater:
                    <input
                        className={styles.input}
                        type="number"
                        value={carbs}
                        placeholder="Karbohydrater"
                        onChange={(e) => setCarbs(e.target.value)}
                    />
                </label>
                <label className={styles.label}>
                    Fett:
                    <input
                        className={styles.input}
                        type="number"
                        value={fat}
                        placeholder="Fett"
                        onChange={(e) => setFat(e.target.value)}
                    />
                </label>
                <label className={styles.label}>
                    Kalorier:
                    <input
                        className={styles.input}
                        type="number"
                        value={kcals}
                        placeholder="Kalorier"
                        onChange={(e) => setKcals(e.target.value)}
                    />
                </label>
                <label className={styles.label}>
                    Mengde:
                    <input
                        className={styles.input}
                        type="number"
                        value={amount}
                        placeholder="Mengde"
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </label>
                <label className={styles.label}>
                    Enhet:
                    <select
                        className={styles.select}
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                    >
                        <option value="g">gram</option>
                        <option value="dl">dl</option>
                        <option value="stk">stk</option>
                    </select>
                </label>
                <div className={styles.button} onClick={handleSubmit}>Send</div>
            </form>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedIngredient?.attributes?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Protein:</strong> {selectedIngredient?.attributes?.macros[0]?.protein} g</p>
                    <p><strong>Fett:</strong> {selectedIngredient?.attributes?.macros[0]?.fat} g</p>
                    <p><strong>Karbohydrater:</strong> {selectedIngredient?.attributes?.macros[0]?.carbs} g</p>
                    <p><strong>Kalorier:</strong> {selectedIngredient?.attributes?.macros[0]?.kcals} kcals</p>
                    <p><strong>Mengde:</strong> {selectedIngredient?.attributes?.amount} {selectedIngredient?.attributes?.unit}</p>
                    {isAdmin && (
                        <>
                            <button className={styles.deleteButton} onClick={handleDeleteIngredient}>Slett</button>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className={styles.closeButton} onClick={handleCloseModal}>Lukk</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}