import { Purchases } from '@revenuecat/purchases-capacitor';

const fetchAndLogProducts = async () => {
  try {
    const offerings = await Purchases.getOfferings();
    console.log('RevenueCat Offerings:', offerings);

    if (offerings.current !== null) {
      console.log('Current Offering:', offerings.current.identifier);
      console.log('Available Packages:');
      offerings.current.availablePackages.forEach((pkg, index) => {
        console.log(`${index + 1}. ${pkg.identifier} - ${pkg.product.price} ${pkg.product.currencyCode}`);
        console.log('   Product Details:', pkg.product);
      });
    } else {
      console.log('No current offering available');
    }
  } catch (error) {
    console.error('Error fetching offerings:', error);
  }
};

export default fetchAndLogProducts;