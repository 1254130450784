import React, {useState, useEffect } from 'react';
import styles from './styles.module.css';
import { useClientInformation } from '../../../hooks/clientInfo';
import Loader from '../../global/loader';
import Image from 'react-bootstrap/Image';
import defaultImage from '../../../images/blue_man.png';
import Modal from 'react-bootstrap/Modal';
import { filterObjects } from '../../global/searchFunctionality';
// import CheckInCards from '../../global/forms/check-in/check-In-cards';
// import ClientCards from '../../global/ClientCard';
import CoachSideCheckInCards from '../../global/forms/check-in/coachSide';
import CoachSideWorkoutCards from '../../coaches/clientsWorkouts';
//import useGetCoachSideCheckInCards from '../../../hooks/coachSideCheckIns';
import { axiosAuthFetch } from '../../../helpers/axios';
import { API_GRAPHQL } from '../../../constants';
import { getLocalStorageItem } from '../../global/storage';

export default function CoachesClientPage() {
    
  const { clients, isLoading, isError } = useClientInformation();
  const [searchText, setSearchText] = useState('');
  //const [checkInSearchText, setCheckInSearchText] = useState('');
  const userInfo = getLocalStorageItem('limitless-user');
  const coachID = userInfo.user.coachID;
  const user = userInfo?.user;
  const [checkIns, setCheckIns] = useState([]);

  const filteredClients = clients ? clients.filter(client => client.id.toString() !== user.id.toString()) : [];

  const query = `query {
                checkIns(filters: { client: { coach: { id: { eq: ${coachID} } } } }, pagination: {limit: 50}, sort:"date:desc") {
                    data {
                        id
                        attributes {
                            date
                            comment
                            rating_training
                            rating_sleep
                            rating_motivation
                            rating_nutrition
                            weight
                            coach_comment
                            client {
                                data {
                                    id
                                    attributes {
                                        username
                                        profile_picture {
                                            data {
                                                id
                                                attributes {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            progress_pic {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }`;

  useEffect(() => {
    async function getCheckIns(){
        try{
            const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
            console.log(response);
            setCheckIns(response.data.checkIns.data);
        } catch(error) {
            console.error(error)
        }
    }
    getCheckIns();
  }, [query]);

  if (isLoading || !clients) {
    return <Loader />;
  }

  if (isError) {
    return <div>Oida, her gikk noe galt! Vennligst prøv igjen!</div>;
  }

  if (clients && clients.length === 0) {
    return <div>Du har ingen klienter for øyeblikket.</div>;
  }
  const searchedClients = filterObjects(searchText, filteredClients);

  return (
    <div className={`${styles.container} appearing-container`}>
        <div className={styles.sub1}>
            <h1 className="text-center">Dine klienter</h1>
            <input
            className={styles.search}
            type="text"
            placeholder="Søk etter klient"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            />
            <CoachSideClientCards clients={searchedClients} />
        </div>
        {/* <div className={styles.nutritionContainer}>
            <div className={styles.lureSub}>
                <NutritionForm user={user} />
            </div>
        </div> */}
        <div className={styles.sub2}>
            <div className={styles.checkInDiv}>
                <div className={styles.checkInHeader}>
                    <div className={styles.checkInOverskrift}>Klienters check-ins</div>
                </div>
                {/* <input
                    className={styles.search}
                    type="text"
                    placeholder="Søk etter klient"
                    value={checkInSearchText}
                    onChange={(e) => setCheckInSearchText(e.target.value)}
                    /> */}
                <div className={styles.checkInCardsContainer}>
                    <CoachSideCheckInCards checkIns={checkIns}/>
                </div>
            </div>
            {/* <div className={styles.subChild1}>
                <h1 className="text-center">Check-in's</h1>
                <CoachSideCheckInCards user={user} />
            </div> */}
        </div>
        <div className={styles.sub3}>
            <div className={styles.subChild2}>
                <div className={styles.checkInHeader}>
                    <div className={styles.checkInOverskrift}>Klienters økter</div>
                </div>
                <div styles={styles.workoutCardsContainer}>
                    <CoachSideWorkoutCards user={user} />
                </div>
            </div>
        </div>
    </div>
  );
}

const CoachSideClientCards = ({ clients }) => {
    const [selectedClient, setSelectedClient] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleClientClick = (client) => {
        setSelectedClient(client);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedClient(null);
    };

    console.log('clients', clients);

    const getFormattedDate = (client) => {
        let formattedDate = "";
        const workout_sessions = client?.attributes?.workout_sessions?.data || [];
        if (workout_sessions.length > 0) {
            const datePerformed = new Date(workout_sessions[workout_sessions.length - 1].attributes.date_performed);
            const months = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
            const day = datePerformed.getDate().toString().padStart(2, '0');
            const month = months[datePerformed.getMonth()];
            formattedDate = `${day}. ${month}`;
        }
        return formattedDate;
    };

    return(
            <div className={styles.cardContainer}>
                {clients.length > 0 ? (
                    clients.map((client) => {
                        const { username } = client.attributes;
                        const imageUrl = client.attributes.profile_picture?.data?.attributes?.url || defaultImage;
                        return (
                            <div key={client.id} className={styles.card} onClick={() => handleClientClick(client)}>
                                <div className={styles.image}>
                                    <div className={styles.profilePictureContainer}>
                                        <Image className={styles.clientProfilePicture} fluid alt="client-image" src={imageUrl} />
                                    </div>
                                </div>
                                <div className={styles.infoDiv}>
                                    <div className={styles.name}>{username}</div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={styles.noClients}>
                        Du har ingen klienter.
                    </div>
                )}
                {/* Modal for å vise klientinformasjon */}
            <Modal size="lg" className={styles.modal} show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Klientdetaljer</Modal.Title>
                </Modal.Header>
                {selectedClient && (
                    <Modal.Body>
                        <div className={styles.modalBody}>
                            <div className={styles.modalImageContainer}>
                                <Image className={styles.modalProfilePicture} fluid alt="client-image" src={selectedClient.attributes.profile_picture?.data?.attributes?.url || defaultImage } />
                            </div>
                            <div className={styles.modalInfo}>
                                <p><strong>Brukernavn:</strong> {selectedClient.attributes.username}</p>
                                <p><strong>Programmer:</strong> {selectedClient.attributes.programs?.data?.length > 0 ? selectedClient.attributes.programs.data.map(prog => prog.attributes.programName).join(', ') : "Ingen programmer"}</p>
                                <p><strong>Matplan:</strong> {selectedClient.attributes.matplan?.data?.attributes?.Name ? selectedClient.attributes.matplan.data.attributes.Name : "Ingen matplan"}</p>
                                <p><strong>Antall økter:</strong> {selectedClient.attributes.workout_sessions?.data?.length || "Ingen treningsøkter"}</p>
                                <p><strong>Siste økt:</strong> {selectedClient.attributes.workout_sessions?.data?.length > 0 ? `${getFormattedDate(selectedClient)}` : "Ingen økter"}</p>
                            </div>
                            {/* <p><strong>Check-in's:</strong></p>
                            {selectedClient.attributes.check_ins?.data.length > 0 ? <CheckInCards checkIns={selectedClient.attributes.check_ins?.data} /> : 'Ingen check-ins' } */}
                        </div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <div className={styles.closeButton} onClick={handleCloseModal}>
                        Lukk
                    </div>
                </Modal.Footer>
            </Modal>
            </div>
    )
};