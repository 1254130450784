import { useState, useEffect} from 'react';
import { getLocalStorageItem } from '../../components/global/storage';
import { API_GRAPHQL } from '../../constants';
import { axiosAuthFetch } from '../../helpers/axios';

export function useClientInformation() {
    const user = getLocalStorageItem('limitless-user');
    const coachID = user.user.coachID;
    const url = API_GRAPHQL;
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        async function getPrograms(url) {

            const query = `query {
                coach(id:${coachID}){
                    data{
                        id
                        attributes{
                            clients(pagination: { limit: 100 }) {
                                data{
                                    id
                                    attributes{
                                        username
                                        blocked
                                        check_ins(pagination: { limit: 100 }){
                                            data{
                                                id
                                                attributes{
                                                    date
                                                    weight
                                                    rating
                                                    comment
                                                    coach_comment
                                                    progress_pic{
                                                        data{
                                                            id
                                                            attributes{
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        workout_sessions(pagination: { limit: 100 }){
                                            data{
                                                id
                                                attributes{
                                                    date_performed
                                                    rating
                                                    duration
                                                    program{
                                                        data{
                                                            id
                                                            attributes{
                                                                programName
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        programs(pagination: { limit: 100 }){
                                            data{
                                                id
                                                attributes{
                                                    programName
                                                    cover_image{
                                                        data{
                                                            id
                                                            attributes{
                                                                images{
                                                                    data{
                                                                        id
                                                                        attributes{
                                                                            url
                                                                        }
                                                                    }
                                                                }
                                                            }  
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        profile_picture{
                                            data{
                                                id
                                                attributes{
                                                    url
                                                }
                                            }
                                        }
                                        matplan{
                                            data{
                                                id
                                                attributes{
                                                    Name
                                                    Matplan{
                                                        data{
                                                            id
                                                            attributes{
                                                                url
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`;
            ;
            try {
                setIsError(false);
                setIsLoading(true);

                const response = await axiosAuthFetch(url, { query }, 'post')
                console.log(response);
                setClients(response.data.coach.data.attributes.clients.data);
            } catch(error) {
                console.error(error);
            }
            finally {
                setIsLoading(false);
            }
        }

        getPrograms(url)
    }, [coachID, url])

    return {clients, isLoading, isError};

}