import React from 'react';
import Modal from 'react-bootstrap/Modal';
import modalCss from './modal.module.css';

function CustomAlertModal({ show, handleClose, content, buttonLabel, submit, redirectUser, modalTitle }) {
    const handleButtonClick = () => {
        if(submit) {
            submit();
        }
        handleClose();
        if(redirectUser) {
            redirectUser();
        }
    }

  return (
    <Modal className={modalCss.container} show={show} onHide={handleClose} size="l">
      <Modal.Header closeButton>
        <Modal.Title className={modalCss.title}>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={modalCss.body}>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleButtonClick}
          className={modalCss.button}>
            {buttonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomAlertModal;