// import React, { useState, useEffect } from 'react';
// import styles from './styles.module.css';
// import defaultImage from '../../../../../images/blue_man.png';
// import Modal from 'react-bootstrap/Modal';
// import { getLocalStorageItem } from '../../../../global/storage';

// export default function ClientCard({ client, mealID, ingredients, createVariant, allIngredients }) {

//     const [showModal, setShowModal] = useState(false);
//     const [customIngredients, setCustomIngredients] = useState(ingredients);
//     const [totalMacros, setTotalMacros] = useState({ protein: 0, carbs: 0, fat: 0, kcals: 0 });
//     const [ingredientSearch, setIngredientSearch] = useState('');
//     const [filteredIngredients, setFilteredIngredients] = useState([]);
//     const [selectedIngredient, setSelectedIngredient] = useState(null);
//     const [newIngredientAmount, setNewIngredientAmount] = useState('');
//     const [newIngredientUnit, setNewIngredientUnit] = useState('g');
//     const userInfo = getLocalStorageItem('limitless-user');
//     const coachID = userInfo.user.coachID;

//     const handleIngredientSearch = (e) => {
//         const searchValue = e.target.value.toLowerCase();
//         setIngredientSearch(searchValue);
//         setFilteredIngredients(
//             searchValue
//                 ? allIngredients.filter((ingredient) =>
//                     ingredient.attributes.name.toLowerCase().includes(searchValue)
//                 )
//                 : []
//         );
//     };

//     const handleAddIngredient = () => {
//         if (!selectedIngredient || !newIngredientAmount) return;

//         const newIngredient = {
//             id: null, // Ny ingrediens har ikke en ID ennå
//             name: selectedIngredient.attributes.name,
//             amount: parseFloat(newIngredientAmount),
//             unit: newIngredientUnit,
//             macros: selectedIngredient.attributes.macros[0],
//             ingredientId: selectedIngredient.id, // For senere bruk
//         };

//         setCustomIngredients([...customIngredients, newIngredient]);
//         setIngredientSearch('');
//         setSelectedIngredient(null);
//         setNewIngredientAmount('');
//         setNewIngredientUnit('g');
//     };

//     console.log(client);

//     const imageUrl = client.profilePicture && client.profilePicture.length > 0 ? client.profilePicture : defaultImage;

//     const handleIngredientChange = (index, newAmount) => {
//         const updatedIngredients = [...customIngredients];
//         updatedIngredients[index] = {
//             ...updatedIngredients[index],
//             amount: parseFloat(newAmount) || 0 // Setter til 0 hvis input er tomt eller ugyldig
//         };
//         setCustomIngredients(updatedIngredients);
//     };

//     const handleRemoveIngredient = (index) => {
//         const updatedIngredients = customIngredients.filter((_, i) => i !== index);
//         setCustomIngredients(updatedIngredients);
//     };

//     const calculateMacros = () => {
//         let totalProtein = 0;
//         let totalCarbs = 0;
//         let totalFat = 0;
//         let totalKcals = 0;

//         customIngredients.forEach(ingredient => {
//             const { amount, macros, unit } = ingredient;

//             // Beregner makroene basert på enheten
//             if (unit === "stk") {
//                 totalProtein += macros.protein * amount;
//                 totalCarbs += macros.carbs * amount;
//                 totalFat += macros.fat * amount;
//                 totalKcals += macros.kcals * amount;
//             } else if (unit === "g") {
//                 totalProtein += (macros.protein / 100) * amount;
//                 totalCarbs += (macros.carbs / 100) * amount;
//                 totalFat += (macros.fat / 100) * amount;
//                 totalKcals += (macros.kcals / 100) * amount;
//             } else if (unit === "dl") {
//                 const gramsEquivalent = amount * 100;
//                 totalProtein += (macros.protein / 100) * gramsEquivalent;
//                 totalCarbs += (macros.carbs / 100) * gramsEquivalent;
//                 totalFat += (macros.fat / 100) * gramsEquivalent;
//                 totalKcals += (macros.kcals / 100) * gramsEquivalent;
//             }
//         });

//         setTotalMacros({
//             protein: Math.round(totalProtein),
//             carbs: Math.round(totalCarbs),
//             fat: Math.round(totalFat),
//             kcals: Math.round(totalKcals)
//         });
//     };

//     useEffect(() => {
//         calculateMacros();
//         //eslint-disable-next-line
//     }, [customIngredients]);

//     const handleSaveVariant = async () => {
//         const variantName = `Tilpasset variant for ${client.username}`;
//         const coachId = coachID;
//         const mealId = mealID;
    
//         const ingredientsForVariant = customIngredients.map(ingredient => ({
//             amount: ingredient.amount,
//             unit: ingredient.unit,
//             ingredientId: ingredient.ingredientId || ingredient.id, // Bruker `ingredientId` hvis tilgjengelig
//             name: ingredient.name,
//             macros: ingredient.macros,
//         }));
    
//         await createVariant({
//             variantName,
//             coachId,
//             mealId,
//             ingredients: ingredientsForVariant,
//             clientId: client.id, // Legg til clientId hvis nødvendig
//         });
    
//         setShowModal(false);
//     };

//     return (
//         <>
//             <div className={styles.clientCard} onClick={() => setShowModal(true)}>
//                 <div style={{backgroundImage: `url(${imageUrl})`}} alt={`${client.username}'s profile`} className={styles.profilePicture} />
//                 <div className={styles.clientInfo}>
//                     <h4>{client.username}</h4>
//                     <p>{client.variantName}</p>
//                 </div>
//             </div>
//             <Modal show={showModal} onHide={() => setShowModal(false)} centered>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Tilpass mengder for {client.username}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body className={styles.container2}>
//                         {customIngredients.map((ingredient, index) => (
//                             <div key={ingredient.id} className={styles.ingredientAdjustContainer}>
//                                 <label>{ingredient.name}</label>
//                                 <input
//                                     type="number"
//                                     value={ingredient.amount}
//                                     onChange={(e) => handleIngredientChange(index, e.target.value)}
//                                     className={styles.ingredientInput}
//                                 />
//                                 <span>{ingredient.unit}</span>
//                                 <button
//                                     onClick={() => handleRemoveIngredient(index)}
//                                     className={styles.removeIngredientButton}
//                                 >
//                                     Fjern
//                                 </button>
//                             </div>
//                         ))}

//                         <div className={styles.addIngredientContainer}>
//                                 <h4>Legg til ingrediens</h4>
//                                 <input
//                                     type="text"
//                                     value={ingredientSearch}
//                                     onChange={handleIngredientSearch}
//                                     placeholder="Søk etter ingrediens"
//                                     className={styles.ingredientSearchInput}
//                                 />
//                                 <div className={styles.filteredIngredients}>
//                                     {filteredIngredients.map((ingredient) => (
//                                         <div
//                                             key={ingredient.id}
//                                             className={`${styles.ingredientOption} ${
//                                                 selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
//                                             }`}
//                                             onClick={() => setSelectedIngredient(ingredient)}
//                                         >
//                                             {ingredient.attributes.name}
//                                         </div>
//                                     ))}
//                                 </div>
//                                 {selectedIngredient && (
//                                     <div className={styles.addIngredientDetails}>
//                                         <input
//                                             type="number"
//                                             value={newIngredientAmount}
//                                             onChange={(e) => setNewIngredientAmount(e.target.value)}
//                                             placeholder="Mengde"
//                                             className={styles.ingredientAmountInput}
//                                         />
//                                         <select
//                                             value={newIngredientUnit}
//                                             onChange={(e) => setNewIngredientUnit(e.target.value)}
//                                             className={styles.ingredientUnitSelect}
//                                         >
//                                             <option value="g">g</option>
//                                             <option value="stk">stk</option>
//                                             <option value="dl">dl</option>
//                                         </select>
//                                         <button onClick={handleAddIngredient} className={styles.addIngredientButton}>
//                                             Legg til ingrediens
//                                         </button>
//                                     </div>
//                                 )}
//                             </div>
//                         <div className={styles.macroSummary}>
//                             <h4>Totale Makroer</h4>
//                             <p><strong>Protein:</strong> {totalMacros.protein}g</p>
//                             <p><strong>Karbohydrater:</strong> {totalMacros.carbs}g</p>
//                             <p><strong>Fett:</strong> {totalMacros.fat}g</p>
//                             <p><strong>Kalorier:</strong> {totalMacros.kcals} kcal</p>
//                         </div>
//                     </Modal.Body>
//                     <Modal.Footer className={styles.modalCustomFooter}>
//                         <div className={styles.submitButton} onClick={handleSaveVariant}>
//                             Lagre variant
//                         </div>
//                         <div className={styles.cancelButton} onClick={() => setShowModal(false)}>
//                             Avbryt
//                         </div>
//                     </Modal.Footer>
//                 </Modal>
//         </>
        
//     );
// }

import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import defaultImage from '../../../../../images/blue_man.png';
import Modal from 'react-bootstrap/Modal';

export default function ClientCard({ client, allIngredients, createVariant, mealId, deleteVariant }) {

    const [showModal, setShowModal] = useState(false);
    const [customIngredients, setCustomIngredients] = useState(client.ingredients || []);
    const [totalMacros, setTotalMacros] = useState({ protein: 0, carbs: 0, fat: 0, kcals: 0 });

    const imageUrl = client.profilePicture || defaultImage;

    // Legg til søkefunksjonalitet for å legge til nye ingredienser
    const [ingredientSearch, setIngredientSearch] = useState('');
    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [newIngredientAmount, setNewIngredientAmount] = useState('');
    const [newIngredientUnit, setNewIngredientUnit] = useState('g');
    const [newVariantName, setNewVariantName] = useState('');

    const handleIngredientSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setIngredientSearch(searchValue);
        setFilteredIngredients(
            searchValue
                ? allIngredients.filter((ingredient) =>
                    ingredient.attributes.name.toLowerCase().includes(searchValue)
                )
                : []
        );
    };

    const handleAddIngredient = () => {
        if (!selectedIngredient || !newIngredientAmount) return;

        const newIngredient = {
            id: null, // Ny ingrediens har ikke en ID
            name: selectedIngredient.attributes.name,
            amount: parseFloat(newIngredientAmount),
            unit: newIngredientUnit,
            macros: selectedIngredient.attributes.macros[0],
            ingredientId: selectedIngredient.id, // Brukes for referanse til backend
        };

        setCustomIngredients([...customIngredients, newIngredient]);
        setIngredientSearch('');
        setSelectedIngredient(null);
        setNewIngredientAmount('');
        setNewIngredientUnit('g');
    };

    const handleRemoveIngredient = (index) => {
        const updatedIngredients = customIngredients.filter((_, i) => i !== index);
        setCustomIngredients(updatedIngredients);
    };

    const handleVariantNameInput = (e) => {
        setNewVariantName(e.target.value);
    }

    const handleIngredientChange = (index, newAmount) => {
        const updatedIngredients = [...customIngredients];
        updatedIngredients[index] = {
            ...updatedIngredients[index],
            amount: parseFloat(newAmount) || '' // Setter til 0 hvis input er tomt eller ugyldig
        };
        setCustomIngredients(updatedIngredients);
    };

    const calculateMacros = () => {
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalFat = 0;
        let totalKcals = 0;

        customIngredients.forEach(ingredient => {
            const { amount, macros, unit } = ingredient;

            if (!macros) return; // Hopp over hvis makroer ikke er tilgjengelig

            // Beregn makroer basert på enhet
            if (unit === "stk") {
                totalProtein += macros.protein * amount;
                totalCarbs += macros.carbs * amount;
                totalFat += macros.fat * amount;
                totalKcals += macros.kcals * amount;
            } else if (unit === "g") {
                totalProtein += (macros.protein / 100) * amount;
                totalCarbs += (macros.carbs / 100) * amount;
                totalFat += (macros.fat / 100) * amount;
                totalKcals += (macros.kcals / 100) * amount;
            } else if (unit === "dl") {
                const gramsEquivalent = amount * 100;
                totalProtein += (macros.protein / 100) * gramsEquivalent;
                totalCarbs += (macros.carbs / 100) * gramsEquivalent;
                totalFat += (macros.fat / 100) * gramsEquivalent;
                totalKcals += (macros.kcals / 100) * gramsEquivalent;
            }
        });

        setTotalMacros({
            protein: Math.round(totalProtein),
            carbs: Math.round(totalCarbs),
            fat: Math.round(totalFat),
            kcals: Math.round(totalKcals)
        });
    };

    const handleDeleteVariant = async () => {
        const confirmed = window.confirm('Er du sikker på at du vil slette denne varianten og sette klienten tilbake på hovedmåltidet?');
        if (!confirmed) return;
      
        try {
          await deleteVariant({
            mealInstanceId: client.mealInstanceId,
            clientId: client.id,
            mealId: mealId,
          });
      
          setShowModal(false);
        } catch (error) {
          console.error('Error deleting variant:', error);
          alert('Det oppstod en feil ved sletting av varianten.');
        } 
      };
    
    useEffect(() => {
        calculateMacros();
        //eslint-disable-next-line
    }, [customIngredients]);

    // const handleSaveVariant = async () => {
    //     const variantName = newVariantName;
    //     const ingredientsForVariant = customIngredients.map(ingredient => ({
    //         amount: ingredient.amount,
    //         unit: ingredient.unit,
    //         ingredientId: ingredient.ingredientId || ingredient.id,
    //         name: ingredient.name,
    //         macros: ingredient.macros,
    //         meal: [mealId]
    //     }));
    
    //     await createVariant({
    //         variantName,
    //         mealId,
    //         ingredients: ingredientsForVariant,
    //         clientId: client.id,
    //         mealInstanceId: client.mealInstanceId || null, // Send med mealInstanceId hvis den finnes
    //     });
    
    //     setShowModal(false);
    // };

    const handleSaveVariant = async (isUpdate) => {
        const ingredientsForVariant = customIngredients.map(ingredient => ({
            amount: ingredient.amount,
            unit: ingredient.unit,
            ingredientId: ingredient.ingredientId || ingredient.id,
            name: ingredient.name,
            macros: ingredient.macros,
            meal: [mealId]
        }));
    
        // Velg mellom oppdatering av eksisterende eller opprettelse av ny variant
        await createVariant({
            variantName: newVariantName,
            mealId,
            ingredients: ingredientsForVariant,
            clientId: client.id,
            mealInstanceId: client.mealInstanceId || null,
            createNew: !isUpdate
        });
    
        setShowModal(false);
    };

    return (
        <>
            <div className={styles.clientCard} onClick={() => setShowModal(true)}>
                <div style={{backgroundImage: `url(${imageUrl})`}} alt={`${client.username}'s profile`} className={styles.profilePicture} />
                <div className={styles.clientInfo}>
                    <h4>{client.username}</h4>
                    <p>{client.variantName}</p>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tilpass mengder for {client.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.container2}>
                    {customIngredients.map((ingredient, index) => (
                        <div key={index} className={styles.ingredientAdjustContainer}>
                            <label>{ingredient.name}</label>
                            <input
                                type="number"
                                value={ingredient.amount}
                                onChange={(e) => handleIngredientChange(index, e.target.value)}
                                className={styles.ingredientInput}
                            />
                            <span>{ingredient.unit}</span>
                            <button
                                onClick={() => handleRemoveIngredient(index)}
                                className={styles.removeIngredientButton}
                            >
                                Fjern
                            </button>
                        </div>
                    ))}

                    {/* Søkefelt for å legge til nye ingredienser */}
                    <div className={styles.addIngredientContainer}>
                        <h4>Legg til ingrediens</h4>
                        <input
                            type="text"
                            value={ingredientSearch}
                            onChange={handleIngredientSearch}
                            placeholder="Søk etter ingrediens"
                            className={styles.ingredientSearchInput}
                        />
                        <div className={styles.filteredIngredients}>
                            {filteredIngredients.map((ingredient) => (
                                <div
                                    key={ingredient.id}
                                    className={`${styles.ingredientOption} ${
                                        selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
                                    }`}
                                    onClick={() => setSelectedIngredient(ingredient)}
                                >
                                    {ingredient.attributes.name}
                                </div>
                            ))}
                        </div>
                        {selectedIngredient && (
                            <div className={styles.addIngredientDetails}>
                                <div className={styles.row}>
                                    <input
                                        type="number"
                                        value={newIngredientAmount}
                                        onChange={(e) => setNewIngredientAmount(e.target.value)}
                                        placeholder="Mengde"
                                        className={styles.ingredientAmountInput}
                                    />
                                    {newIngredientUnit}
                                </div>
                                {/* <select
                                    value={newIngredientUnit}
                                    onChange={(e) => setNewIngredientUnit(e.target.value)}
                                    className={styles.ingredientUnitSelect}
                                >
                                    <option value="g">g</option>
                                    <option value="stk">stk</option>
                                    <option value="dl">dl</option>
                                </select> */}
                                <button onClick={handleAddIngredient} className={styles.addIngredientButton}>
                                    Legg til ingrediens
                                </button>
                            </div>
                        )}
                    </div>

                    <div className={styles.macroSummary}>
                        <h4>Totale Makroer</h4>
                        <p><strong>Protein:</strong> {totalMacros.protein}g</p>
                        <p><strong>Karbohydrater:</strong> {totalMacros.carbs}g</p>
                        <p><strong>Fett:</strong> {totalMacros.fat}g</p>
                        <p><strong>Kalorier:</strong> {totalMacros.kcals} kcal</p>
                    </div>
                    <div className={styles.nameInput}>
                        <label>Navn på variant</label>
                        <input 
                            className={styles.ingredientSearchInput}
                            placeholder="Navn på varianten"
                            value={newVariantName}
                            onChange={handleVariantNameInput}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className={styles.modalCustomFooter}>
                    <button className={styles.submitButton} disabled={!newVariantName.trim()} onClick={() => handleSaveVariant(false)}>
                        Lagre som ny variant
                    </button>
                    {client.isVariant && (
                        <button onClick={() => handleSaveVariant(true)} disabled={!newVariantName.trim()} className={styles.updateVariantButton}>
                            Oppdater eksisterende variant
                        </button>
                    )}
                    {client.isVariant && (
                        <button onClick={handleDeleteVariant} className={styles.deleteVariantButton}>
                            Slett variant
                        </button>
                    )}
                    <div className={styles.cancelButton} onClick={() => setShowModal(false)}>
                        Avbryt
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

