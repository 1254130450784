import React, {useState, useEffect} from 'react';
import styles from './styles.module.css';
import { axiosImageAuthFetch, axiosAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../loader';

export default function CreateExerciseForm() {
    const [name, setName] = useState('');
    const [file, setImage] = useState();
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [selectedMuscleGroup, setSelectedMuscleGroup] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchMuscleGroups = async () => {
            try {
                const response = await axiosAuthFetch(`${API_BASE_URL}/muscles`, {}, 'GET');
                setMuscleGroups(response.data);
            } catch (error) {
                console.error('Feil ved henting av muskelgrupper', error);
            }
        };
        fetchMuscleGroups();
    }, []);

    const handleExerciseNameChange = (e) => {
        setName(e.target.value);
    }

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    }

    const handleMuscleGroupChange = (e) => {
        setSelectedMuscleGroup(e.target.value);
    };

    const handleSubmit = async () => {

        try {
            setIsLoading(true);

            const data = {
                name: name,
                muscles: selectedMuscleGroup,
            };
    
            const exerciseResponse = await axiosAuthFetch(`${API_BASE_URL}/exercises`, { data }, 'POST');
            console.log(exerciseResponse);
            const exerciseID = exerciseResponse.data.id;

            const formData = new FormData();
            formData.append('ref', 'api::exercise.exercise');
            formData.append('refId', exerciseID);
            formData.append('field', 'image');
            formData.append('files', file)

            const populateExerciseWithImage = await axiosImageAuthFetch(`${API_BASE_URL}/upload`, formData, 'POST');
            console.log(populateExerciseWithImage);
            alert('Øvelse opprettet!')
        } catch(error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    if(isLoading) {
        return(
            <Loader />
        )
    }

    return(
        <div className={styles.container}>
            <div className={styles.header}>Legg til øvelse</div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <input 
                    className={styles.input}
                    placeholder="Navn på øvelse"
                    value={name}
                    onChange={handleExerciseNameChange}
                />
                <select
                    className={styles.select}
                    value={selectedMuscleGroup}
                    onChange={handleMuscleGroupChange}
                >
                    <option value="">Velg muskelgruppe</option>
                    {muscleGroups.map((muscle) => (
                        <option key={muscle.id} value={muscle.id}>
                            {muscle.attributes.Name}
                        </option>
                    ))}
                </select>
                <input type="file" onChange={handleImageChange} />
                <div className={styles.submitButton} onClick={handleSubmit}>Send</div>
            </form>
        </div>
    )
}