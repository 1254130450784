// import React, {useEffect} from 'react';
// import loginCSS from './login.module.css';
// import LoginForm from '../../global/forms/loginForm';
// import Logo from '../../../images/logo.png';
// import Image from 'react-bootstrap/Image';
// import { useNavigate } from 'react-router-dom';
// import { getLocalStorageItem } from '../../global/storage';

// export default function LoginPage() {

//     const navigate = useNavigate();

//     useEffect(() => {
//         const userInfo = getLocalStorageItem('limitless-user');
//         if (userInfo) {
//         // If user is logged in, redirect to the home page or any other page
//         navigate('/home');
//         }
//     }, [navigate]);

//     const redirectUser = () => {
//         window.location.href='/register';
//     }
//     return(
//         <div className={loginCSS.hider}>
//             <div className={loginCSS.logoContainer}>
//                 <Image fluid alt="Limitless Logo" src={Logo} />
//             </div>
//             <div className={loginCSS.container}>
//                 <LoginForm />
//             </div>
//             <div className={loginCSS.container2} onClick={redirectUser}>
//                 <div className={loginCSS.tekst}>Har du ikke konto? Trykk her for å registrere deg</div>
//             </div>
//         </div>
//     )
// }

import React, { useEffect } from 'react';
import loginCSS from './login.module.css';
import LoginForm from '../../global/forms/loginForm';
import Logo from '../../../images/logo.png';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem } from '../../global/storage';
import { Capacitor } from '@capacitor/core';
import { Purchases } from '@revenuecat/purchases-capacitor';

export default function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = getLocalStorageItem('limitless-user');
    if (userInfo) {
      navigate('/home');
    }
  }, [navigate]);

  const redirectUser = () => {
    window.location.href = '/register';
  };

  const restorePurchases = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        await Purchases.configure({ apiKey: 'appl_NUzkxeLoyOWNYaYOerdtDfziPNu' });
        const restoreResult = await Purchases.restorePurchases();
        console.log('Restore result:', restoreResult);
  
        if (
          restoreResult.customerInfo.activeSubscriptions.length > 0 ||
          Object.keys(restoreResult.customerInfo.entitlements.active).length > 0
        ) {
          alert('Kjøp gjenopprettet! Vennligst logg inn!');
          navigate('/login')
        } else {
          alert('Ingen tidligere kjøp å gjenopprette.');
        }
      } else {
        alert('Gjenoppretting av kjøp er ikke tilgjengelig på denne plattformen.');
      }
    } catch (error) {
      console.error('Error restoring purchases:', error);
      alert('Feil ved gjenoppretting av kjøp. Vennligst prøv igjen.');
    }
  };

  return (
    <div className={loginCSS.hider}>
      <div className={loginCSS.logoContainer}>
        <Image fluid alt="Limitless Logo" src={Logo} />
      </div>
      <div className={loginCSS.container}>
        <LoginForm />
      </div>
      <div className={loginCSS.container2} onClick={redirectUser}>
        <div className={loginCSS.tekst}>
          Har du ikke konto? Trykk her for å registrere deg
        </div>
      </div>
      <div className={loginCSS.restorePurchasesContainer}>
        <div onClick={restorePurchases} className={loginCSS.restoreButton}>
          Gjenopprett kjøp
        </div>
      </div>
    </div>
  );
}
