// import React, {useState, useEffect} from 'react';
// // import ReactSelect from 'react-select';
// import createForm from './cp.module.css';
// //import axios from 'axios';
// import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
// //import useGetUsers from '../../../../helpers/users';
// import Loader from '../../loader';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import {SearchableUserDropdown, SearchableExercisesDropdown} from '../../searchableDropdown';
// import { getLocalStorageItem } from '../../storage';
// import { v4 as uuidv4 } from 'uuid';
// import CustomAlert from '../../customAlert';

// export default function CreateProgramForm({ options }) {

//     // const [selectedExercises, setSelectedExercises] = useState([{ id: 1, value: null, writtenInput: '' }]);
//     const [programName, setProgramName] = useState('');
//     const [isLoading, setIsLoading] = useState(false);
//     const [isError, setIsError] = useState(false);
//     const [users, setUsers] = useState([]);
//     const [UserID, setUserID] = useState(null);
//     const coachUserInfo = getLocalStorageItem('limitless-user');
//     const coachID = coachUserInfo.user.coachID;
//     const [pairs, setPairs] = useState([{id: uuidv4(), description: '', exercise: '', comment: ''}]);
//     const [showAlert, setShowAlert] = useState(false);
//     const [coverPhotos, setCoverPhotos] = useState([]);

//     const query = ` 
//                 query{
//                     coaches(filters: {id:{eq:${coachID}}}) {
//                     data{
//                         id
//                         attributes{
//                         clients(pagination: { limit: 100}){
//                             data{
//                             id
//                             attributes{
//                                 username
//                             }
//                             }
//                         }
//                         }
//                     }
//                     }
//                 }
//             `;

//     useEffect(() => {
//         async function getUsers() {
//             setIsLoading(true);
//             setIsError(false);
//             try {
//                 const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
//                 console.log(response.data.coaches.data[0].attributes.clients.data);
//                 setUsers(response.data.coaches.data[0].attributes.clients.data);
//                 // console.log(users);
//             } catch(error) {
//                 console.error(error)
//                 setIsError(true)
//             } finally {
//                 setIsLoading(false);
//             }
//         }
//         getUsers();
//         //eslint-disable-next-line
//     }, [query]);

//     // const query2 = `
//     //     query{
//     //         coverImagesPlural{
//     //         data{
//     //             id
//     //             attributes{
//     //             images{
//     //                 data{
//     //                 id
//     //                 attributes{
//     //                     url
//     //                 }
//     //                 }
//     //             }
//     //             }
//     //         }
//     //         }
//     //     }`;

//     useEffect(() => {
//         async function getCoverPhotos() {
//             try {
//                 const response = await axiosAuthFetch(`${API_BASE_URL}/cover-images-plural`);
//                 setCoverPhotos(response.data);
//             } catch(error) {
//                 console.error(error);
//             }
//         }
//         getCoverPhotos();
//         //eslint-disable-next-line
//     }, []);

//     if(isLoading) {
//         return <> <Loader /> </>
//     } if(isError) {
//         return (
//             <div>Error</div>
//         )
//     }

//     const getRandomCoverPhoto = () => {
//         if (coverPhotos.length > 0) {
//             const randomIndex = Math.floor(Math.random() * coverPhotos.length);
//             return coverPhotos[randomIndex];
//         }
//         return null;
//     };
    

//     const handleExerciseChange = (pairId, selectedOption) => {
//         setPairs(prevPairs =>
//             prevPairs.map(pair =>
//                 pair.id === pairId ? {...pair, exercise: selectedOption } : pair)
//         );
//     };

//     const handleDescriptionChange = (pairId, event) => {
//         setPairs(prevPairs =>
//             prevPairs.map(pair =>
//                 pair.id === pairId ? {...pair, description: event.target.value} : pair));
//     };

//     const handleCommentChange = (pairId, event) => {
//         setPairs(prevPairs =>
//             prevPairs.map(pair =>
//                 pair.id === pairId ? { ...pair, comment: event.target.value } : pair
//             )
//         );
//     };

//     const handleUserChange = selectedOption => {
//         if (selectedOption) {
//             setUserID(selectedOption.id);
//         } else {
//             setUserID(''); // Eller en tom verdi som gir mening for din app
//         }
//     };

//     const handleAddPair = () => {
//         setPairs([...pairs, {id: uuidv4(), description: '', exercise: '' , comment: ''}]);
//     };

//     const handleRemovePair = (pairId) => {
//         setPairs(prevPairs => prevPairs.filter(pair => pair.id !== pairId))
//     };

    


//     const createPair = async (exercise, description, comment) => {
//         try {
//             const response = await axiosAuthFetch(`${API_BASE_URL}/pairs`, {
//                 data: {
//                     exercise: exercise.id,
//                     description: description,
//                     comment: comment,
//                 }
//             }, 'POST');
//             return response.data.id;
//         } catch(error) {
//             console.error(error);
//         }
//     };

//     const handleCreateProgram = async () => {
//         if (!programName.trim()) {
//         alert("Vennligst angi et navn på programmet.");
//         return;
//     }
//     if (pairs.length === 0 || !pairs[0].exercise) {
//         alert("Vennligst legg til minst én øvelse før du oppretter programmet.");
//         return;
//     }
//         setIsLoading(true);
//         try{
//             const pairIDs = [];

//             for (const pair of pairs) {
//                 try {
//                     const pairID = await createPair(pair.exercise, pair.description, pair.comment);
//                     pairIDs.push(pairID);
//                 } catch(error) {
//                     console.error(error)
//                 }
//             }

//             const randomCoverPhoto = getRandomCoverPhoto();

//             const programData = {
//                 data: {
//                     programName: programName,
//                     users: UserID,
//                     created_by_coach: [coachID],
//                     pairs: pairIDs,
//                     cover_image: randomCoverPhoto ? randomCoverPhoto.id : null,
//                 }
//             };
    
//             const creationResponse = await axiosAuthFetch(`${API_BASE_URL}/programs`, programData, 'POST');
//             console.log(creationResponse);
//             setIsLoading(false);
//             setShowAlert(true);
//         } catch(error) {
//             console.error(error);
//             setIsLoading(false);
//         }
//     };

//     const handleAlertClose = () => {
//         setShowAlert(false);
//         window.location.reload();
//     }

//     return (
//         <div className={createForm.container1}>
//             <CustomAlert 
//                 show={showAlert}
//                 handleClose={handleAlertClose}
//                 buttonLabel="Den er grei!"
//                 content="Programmet er opprettet!"
//                 title="Suksess!"
//             />
//             <input 
//                 className={createForm.writtenInputHeader}
//                 placeholder='Navn på program'
//                 value = {programName}
//                 onChange={(event) => setProgramName(event.target.value)}
//                 required
//             />
//             <div className={createForm.userText}>Velg klient</div>
//             <SearchableUserDropdown 
//                 objects={users}
//                 onSelect={handleUserChange}
//             />
//             <div className={createForm.smallText}>(Hvis du ikke velger en klient blir programmet lagret som en mal og du finner det igjen
//                 under dine program, hvor du senere kan tildele det til én eller flere klienter)
//             </div>
//             <div className={createForm.userText}>Velg øvelser</div>
//             <div className={createForm.cardContainer}>
//                 {pairs.map((pair, index) => (
//                     <div className={createForm.container2} key={pair.id}>
//                         <div className={createForm.underoverskrift}> Øvelse {index + 1}</div>
//                         <SearchableExercisesDropdown 
//                             objects={options}
//                             onSelect={(selectedOption) => handleExerciseChange(pair.id, selectedOption)}
//                             selectedValue={pair.exercise}
//                             id={`exercise-dropdown-${index}`}
//                         />
//                         <input
//                             className={createForm.writtenInput}
//                             placeholder="Sett"
//                             value={pair.description}
//                             onChange={(event) => handleDescriptionChange(pair.id, event)}
//                         />
//                         <input
//                             className={createForm.writtenInput}
//                             placeholder="Reps"
//                             value={pair.comment}
//                             onChange={(event) => handleCommentChange(pair.id, event)}
//                         />
//                         {index !== 0 && (
//                             <button className={`${createForm.button} ${createForm.removeButton}`} onClick={() => handleRemovePair(pair.id)}>Fjern øvelse</button>
//                         )}
//                     </div>
//                 ))}
//             </div>
//             <button className={createForm.button} onClick={handleAddPair}>Legg til øvelse</button>
//             <button className={`${createForm.button} ${createForm.createButton}`} onClick={handleCreateProgram}>Opprett program</button>
//         </div>
//     );
// }


import React, {useState, useEffect} from 'react';
import createForm from './cp.module.css';
import { API_BASE_URL, API_GRAPHQL } from '../../../../constants';
import Loader from '../../loader';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { SearchableUserDropdown, SearchableExercisesDropdown } from '../../searchableDropdown';
import { getLocalStorageItem } from '../../storage';
import { v4 as uuidv4 } from 'uuid';
import CustomAlert from '../../customAlert';

export default function CreateProgramForm({ options }) {
    const [programName, setProgramName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [users, setUsers] = useState([]);
    const [UserID, setUserID] = useState(null);
    const coachUserInfo = getLocalStorageItem('limitless-user');
    const coachID = coachUserInfo.user.coachID;
    
    // Endret struktur:
    // pairs: hver pair representerer én øvelse i programmet.
    // Nå bruker vi "sets" array for å holde oversikt over flere sett.
    // Hvert "setObj" har kun "reps"-felt (og vi beholder comment på pair-nivå for hele øvelsen, 
    // eller du kan tilpasse slik at comment er per sett hvis du ønsker).
    const [pairs, setPairs] = useState([{
        id: uuidv4(),
        exercise: null,
        sets: [{ id: uuidv4(), reps: '' }], 
        comment: ''
    }]);

    const [showAlert, setShowAlert] = useState(false);
    const [coverPhotos, setCoverPhotos] = useState([]);

    const query = ` 
        query {
            coaches(filters: {id:{eq:${coachID}}}) {
                data {
                    id
                    attributes {
                        clients(pagination: { limit: 100 }){
                            data {
                                id
                                attributes {
                                    username
                                }
                            }
                        }
                    }
                }
            }
        }`;

    useEffect(() => {
        async function getUsers() {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
                setUsers(response.data.coaches.data[0].attributes.clients.data);
            } catch (error) {
                console.error(error);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        }
        getUsers();
        //eslint-disable-next-line
    }, [query]);

    useEffect(() => {
        async function getCoverPhotos() {
            try {
                const response = await axiosAuthFetch(`${API_BASE_URL}/cover-images-plural`);
                setCoverPhotos(response.data);
            } catch(error) {
                console.error(error);
            }
        }
        getCoverPhotos();
        //eslint-disable-next-line
    }, []);

    if(isLoading) {
        return <Loader />
    } 
    if(isError) {
        return <div>Error</div>
    }

    const getRandomCoverPhoto = () => {
        if (coverPhotos.length > 0) {
            const randomIndex = Math.floor(Math.random() * coverPhotos.length);
            return coverPhotos[randomIndex];
        }
        return null;
    };

    const handleUserChange = selectedOption => {
        if (selectedOption) {
            setUserID(selectedOption.id);
        } else {
            setUserID('');
        }
    };

    const handleExerciseChange = (pairId, selectedOption) => {
        setPairs(prev =>
            prev.map(pair =>
                pair.id === pairId ? { ...pair, exercise: selectedOption } : pair
            )
        );
    };

    // const handleCommentChange = (pairId, event) => {
    //     setPairs(prevPairs =>
    //         prevPairs.map(pair =>
    //             pair.id === pairId ? { ...pair, comment: event.target.value } : pair
    //         )
    //     );
    // };

    // Legg til en ny pair (ny øvelse)
    const handleAddPair = () => {
        setPairs([...pairs, {
            id: uuidv4(),
            exercise: null,
            sets: [{ id: uuidv4(), reps: ''}],
            comment: ''
        }]);
    };

    // Fjern en øvelse
    const handleRemovePair = (pairId) => {
        setPairs(prevPairs => prevPairs.filter(pair => pair.id !== pairId))
    };

    // Legg til et nytt sett
    const handleAddSet = (pairId) => {
        setPairs(prev =>
            prev.map(pair =>
                pair.id === pairId ? {
                    ...pair,
                    sets: [...pair.sets, { id: uuidv4(), reps: ''}]
                } : pair
            )
        );
    };

    // Fjern et sett
    const handleRemoveSet = (pairId, setId) => {
        setPairs(prev =>
            prev.map(pair => {
                if (pair.id === pairId) {
                    return {
                        ...pair,
                        sets: pair.sets.filter(s => s.id !== setId)
                    }
                }
                return pair;
            })
        );
    };

    // Oppdatere reps i et sett
    const handleSetRepsChange = (pairId, setId, value) => {
        setPairs(prev =>
            prev.map(pair => {
                if (pair.id === pairId) {
                    return {
                        ...pair,
                        sets: pair.sets.map(s => s.id === setId ? { ...s, reps: value } : s)
                    }
                }
                return pair;
            })
        );
    };

    // Funksjon for å opprette en pair i Strapi
    // const createPair = async (exerciseId, description, comment) => {
    //     try {
    //         const response = await axiosAuthFetch(`${API_BASE_URL}/pairs`, {
    //             data: {
    //                 exercise: exerciseId,
    //                 description: description, // Her lagres "12/10/8"
    //                 comment: comment
    //             }
    //         }, 'POST');
    //         return response.data.id;
    //     } catch(error) {
    //         console.error(error);
    //     }
    // };
    const createPair = async (exerciseId, sets, repsArray) => {
        try {
            const description = sets.length.toString(); // Antall sett
            const comment = repsArray.join('/'); // Reps per sett separert med "/"
    
            const response = await axiosAuthFetch(`${API_BASE_URL}/pairs`, {
                data: {
                    exercise: exerciseId,
                    description: description, // Antall sett
                    comment: comment          // Reps per sett (10/12/8)
                }
            }, 'POST');
            return response.data.id;
        } catch (error) {
            console.error("Error creating pair:", error);
        }
    };

    // const handleCreateProgram = async () => {
    //     // Validering
    //     if (!programName.trim()) {
    //         alert("Vennligst angi et navn på programmet.");
    //         return;
    //     }

    //     for (const pair of pairs) {
    //         if (!pair.exercise) {
    //             alert("Vennligst velg en øvelse før du oppretter programmet.");
    //             return;
    //         }
    //         if (pair.sets.length === 0 || pair.sets.some(s => !s.reps.trim())) {
    //             alert("Vennligst fyll inn reps for alle sett.");
    //             return;
    //         }
    //     }

    //     setIsLoading(true);
    //     try {
    //         const randomCoverPhoto = getRandomCoverPhoto();

    //         // Opprett pairs
    //         const pairIDs = [];
    //         for (const pair of pairs) {
    //             // Konverter sets-array til slash-separert streng
    //             const repsArray = pair.sets.map(s => s.reps.trim());
    //             const description = repsArray.join('/');

    //             const pairID = await createPair(pair.exercise.id, description, pair.comment);
    //             pairIDs.push(pairID);
    //         }

    //         // Opprett program
    //         const programData = {
    //             data: {
    //                 programName: programName,
    //                 users: UserID,
    //                 created_by_coach: [coachID],
    //                 pairs: pairIDs,
    //                 cover_image: randomCoverPhoto ? randomCoverPhoto.id : null,
    //             }
    //         };

    //         const creationResponse = await axiosAuthFetch(`${API_BASE_URL}/programs`, programData, 'POST');
    //         console.log(creationResponse);
    //         setIsLoading(false);
    //         setShowAlert(true);
    //     } catch(error) {
    //         console.error(error);
    //         setIsLoading(false);
    //     }
    // };

    const handleCreateProgram = async () => {
        // Validering
        if (!programName.trim()) {
            alert("Vennligst angi et navn på programmet.");
            return;
        }
    
        for (const pair of pairs) {
            if (!pair.exercise) {
                alert("Vennligst velg en øvelse før du oppretter programmet.");
                return;
            }
            if (pair.sets.length === 0 || pair.sets.some(s => !s.reps.trim())) {
                alert("Vennligst fyll inn reps for alle sett.");
                return;
            }
        }
    
        setIsLoading(true);
        try {
            const randomCoverPhoto = getRandomCoverPhoto();
    
            // Opprett pairs
            const pairIDs = [];
            for (const pair of pairs) {
                // Generer description (antall sett) og comment (reps per sett)
                const repsArray = pair.sets.map(s => s.reps.trim()); // F.eks. ["10", "12", "8"]
                const pairID = await createPair(pair.exercise.id, pair.sets, repsArray);
                pairIDs.push(pairID);
            }
    
            // Opprett program
            const programData = {
                data: {
                    programName: programName,
                    users: UserID,
                    created_by_coach: [coachID],
                    pairs: pairIDs,
                    cover_image: randomCoverPhoto ? randomCoverPhoto.id : null,
                }
            };
    
            const creationResponse = await axiosAuthFetch(`${API_BASE_URL}/programs`, programData, 'POST');
            console.log(creationResponse);
            setIsLoading(false);
            setShowAlert(true);
        } catch (error) {
            console.error("Error creating program:", error);
            setIsLoading(false);
        }
    };    

    const handleAlertClose = () => {
        setShowAlert(false);
        window.location.reload();
    };

    return (
        <div className={createForm.container1}>
            <CustomAlert 
                show={showAlert}
                handleClose={handleAlertClose}
                buttonLabel="Den er grei!"
                content="Programmet er opprettet!"
                title="Suksess!"
            />
            <input
                className={createForm.writtenInputHeader}
                placeholder='Navn på program'
                value={programName}
                onChange={(event) => setProgramName(event.target.value)}
                required
            />
            <div className={createForm.userText}>Velg klient</div>
            <SearchableUserDropdown
                objects={users}
                onSelect={handleUserChange}
            />
            <div className={createForm.smallText}>
                (Hvis du ikke velger en klient blir programmet lagret som en mal, som du kan tildele senere.)
            </div>
            <div className={createForm.userText}>Legg til øvelser & sett</div>
            
            <div className={createForm.cardContainer}>
                {pairs.map((pair, index) => (
                    <div key={pair.id} className={createForm.container2}>
                        <div className={createForm.underoverskrift}>Øvelse {index + 1}</div>
                        <SearchableExercisesDropdown
                            objects={options}
                            onSelect={(selectedOption) => handleExerciseChange(pair.id, selectedOption)}
                            selectedValue={pair.exercise}
                            id={`exercise-dropdown-${index}`}
                        />
                        <div className={createForm.setsContainer}>
                            {pair.sets.map((setObj, setIndex) => (
                                <div key={setObj.id} className={createForm.setRow}>
                                    <div className={createForm.setIndex}>Sett {setIndex+1}</div>
                                    <input
                                        className={createForm.setInput}
                                        placeholder="Reps"
                                        value={setObj.reps}
                                        onChange={(e) => handleSetRepsChange(pair.id, setObj.id, e.target.value)}
                                    />
                                    {/* {pair.sets.length > 1 && (
                                        <button
                                            className={`${createForm.button} ${createForm.removeButton}`}
                                            onClick={() => handleRemoveSet(pair.id, setObj.id)}
                                        >
                                            Fjern sett
                                        </button>
                                    )} */}
                                        <button
                                            className={`${createForm.button} ${createForm.removeButton}`}
                                            onClick={() => handleRemoveSet(pair.id, setObj.id)}
                                        >
                                            Fjern sett
                                        </button>
                                </div>
                            ))}
                            <div className={createForm.addButtonDiv}>
                                <button className={createForm.button} onClick={() => handleAddSet(pair.id)}>Legg til sett</button>
                            </div>
                        </div>
                        {index !== 0 && (
                            <button className={`${createForm.button} ${createForm.removeButton}`} onClick={() => handleRemovePair(pair.id)}>Fjern øvelse</button>
                        )}
                    </div>
                ))}
            </div>

            <button className={createForm.button} onClick={handleAddPair}>Legg til øvelse</button>
            <button className={`${createForm.button} ${createForm.createButton}`} onClick={handleCreateProgram}>
                Opprett program
            </button>
        </div>
    );
}

