// import React, { useState, useEffect } from 'react';
// import styles from './styles.module.css';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import { API_GRAPHQL } from '../../../../constants';
// import { getLocalStorageItem } from '../../../global/storage';
// import MealsPage from '../page';

// export default function MealPageLayoutClientSide() {

//     const userInfo = getLocalStorageItem('limitless-user');
//     const user = userInfo.user;

//     const [mealInstance, setMealInstances] = useState([]);
//     const [mealFallback, setMealFallback] = useState([]);

//     const mealInstanceQuery = `
//                             query{
//                         mealInstances(filters: { clients: { id: { eq: ${user.id} } } }) {
//                             data{
//                             id
//                             attributes{
//                                 custom_ingredients
//                                 meal{
//                                 data{
//                                     id
//                                     attributes{
//                                     name
//                                     description
//                                     category
//                                     cooking_time
//                                     cooking_instructions
//                                     prep_time
//                                     serving
//                                     preparation
//                                     meal_images{
//                                         data{
//                                         id
//                                         attributes{
//                                             image{
//                                             data{
//                                                 id
//                                                 attributes{
//                                                 url
//                                                 }
//                                             }
//                                             }
//                                         }
//                                         }
//                                     }
//                                     }
//                                 }
//                                 }
//                             }
//                             }
//                         }
//                         }
//         `;

//         const mealQuery =`query{
//             meals(filters: { clients: { id: { eq: ${user.id} } } }) {
//                 data{
//                 id
//                 attributes{
//                     name
//                     description
//                     meal_images{
//                         data{
//                             id
//                             attributes{
//                                 image{
//                                     data{
//                                         id
//                                         attributes{
//                                             url
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     meal_ingredients{
//                     data{
//                     id
//                     attributes{
//                         unit
//                         amount
//                         ingredients{
//                         data{
//                             id
//                             attributes{
//                             name
//                             macros{
//                                 fat
//                                 carbs
//                                 kcals
//                                 protein
//                             }
//                             }
//                         }
//                         }
//                     }
//                     }
//                     }
//                     serving
//                     category
//                     preparation
//                     prep_time
//                     cooking_time
//                     cooking_instructions
//                 }
//                 }
//             }
//             }`;

//     useEffect(() => {
//     async function fetchMealData() {
//         const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query: mealInstanceQuery }, 'POST');
//         console.log(response);
//         if (response.data.mealInstances.data.length > 0) {
//         setMealInstances(response.data.mealInstances.data);
//         } else {
//         // Fallback til meal hvis ingen instance er funnet
//         const mealResponse = await axiosAuthFetch(`${API_GRAPHQL}`, { query: mealQuery }, 'POST');
//         console.log(mealResponse);
//         setMealFallback(mealResponse.data.meals.data);
//         }
//     }
//     fetchMealData();
//     //eslint-disable-next-line
//     }, []);

//     const displayedMeal = mealInstance ? mealInstance : mealFallback;
//     console.log(displayedMeal);

//     return(
//         <div className={styles.container}>
//             <MealsPage mealInstances={displayedMeal} />
//         </div>
//     )
// }

// import React, { useState, useEffect } from 'react';
// import styles from './styles.module.css';
// import { axiosAuthFetch } from '../../../../helpers/axios';
// import { API_GRAPHQL } from '../../../../constants';
// import { getLocalStorageItem } from '../../../global/storage';
// import MealsPage from '../page';
// import Loader from '../../../global/loader';

// export default function MealPageLayoutClientSide() {
//     const userInfo = getLocalStorageItem('limitless-user');
//     const user = userInfo.user;

//     const [mealInstances, setMealInstances] = useState([]);
//     const [meals, setMeals] = useState([]);
//     const [isMealInstance, setIsMealInstance] = useState(false); // Legger til flagg for å indikere type
//     const [isLoading, setIsLoading] = useState(false);

//     const mealInstanceQuery = `
//         query {
//             mealInstances(filters: { clients: { id: { eq: ${user.id} } } }) {
//                 data {
//                     id
//                     attributes {
//                         custom_ingredients
//                         meal {
//                             data {
//                                 id
//                                 attributes {
//                                     name
//                                     description
//                                     category
//                                     cooking_time
//                                     cooking_instructions
//                                     prep_time
//                                     serving
//                                     preparation
//                                     meal_images {
//                                         data {
//                                             id
//                                             attributes {
//                                                 image {
//                                                     data {
//                                                         id
//                                                         attributes {
//                                                             url
//                                                         }
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     `;

//     const mealQuery = `
//         query {
//             meals(filters: { clients: { id: { eq: ${user.id} } } }) {
//                 data {
//                     id
//                     attributes {
//                         name
//                         description
//                         category
//                         cooking_time
//                         cooking_instructions
//                         prep_time
//                         serving
//                         preparation
//                         meal_images {
//                             data {
//                                 id
//                                 attributes {
//                                     image {
//                                         data {
//                                             id
//                                             attributes {
//                                                 url
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                         meal_ingredients {
//                             data {
//                                 id
//                                 attributes {
//                                     unit
//                                     amount
//                                     ingredients {
//                                         data {
//                                             id
//                                             attributes {
//                                                 name
//                                                 macros {
//                                                     fat
//                                                     carbs
//                                                     kcals
//                                                     protein
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     `;

//     useEffect(() => {
//         async function fetchMealData() {
//             setIsLoading(true);
//             const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query: mealInstanceQuery }, 'POST');
//             if (response.data.mealInstances.data.length > 0) {
//                 setMealInstances(response.data.mealInstances.data);
//                 setIsMealInstance(true);
//                 setIsLoading(false);
//             } else {
//                 const mealResponse = await axiosAuthFetch(`${API_GRAPHQL}`, { query: mealQuery }, 'POST');
//                 setMeals(mealResponse.data.meals.data);
//                 setIsMealInstance(false);
//                 setIsLoading(false);
//             }
//         }
//         fetchMealData();
//         //eslint-disable-next-line
//     }, []);

//     const displayedMeals = isMealInstance ? mealInstances : meals;

//     console.log(displayedMeals);

//     if(isLoading) {
//         return(
//             <Loader />
//         )
//     }
//     return (
//         <div className={styles.container}>
//             <MealsPage mealInstances={displayedMeals} isMealInstance={isMealInstance} />
//         </div>
//     );
// }

import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch } from '../../../../helpers/axios';
import { API_GRAPHQL } from '../../../../constants';
import { getLocalStorageItem } from '../../../global/storage';
import MealsPage from '../page';
import Loader from '../../../global/loader';

export default function MealPageLayoutClientSide() {
    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo.user;

    const [allMeals, setAllMeals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const mealInstanceQuery = `
        query {
            mealInstances(filters: { clients: { id: { eq: ${user.id} } } }) {
                data {
                    id
                    attributes {
                        custom_ingredients
                        meal {
                            data {
                                id
                                attributes {
                                    name
                                    description
                                    category
                                    cooking_time
                                    cooking_instructions
                                    prep_time
                                    serving
                                    preparation
                                    meal_images {
                                        data {
                                            id
                                            attributes {
                                                image {
                                                    data {
                                                        id
                                                        attributes {
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const mealQuery = `
        query {
            meals(filters: { clients: { id: { eq: ${user.id} } } }) {
                data {
                    id
                    attributes {
                        name
                        description
                        category
                        cooking_time
                        cooking_instructions
                        prep_time
                        serving
                        preparation
                        meal_images {
                            data {
                                id
                                attributes {
                                    image {
                                        data {
                                            id
                                            attributes {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        meal_ingredients {
                            data {
                                id
                                attributes {
                                    unit
                                    amount
                                    ingredients {
                                        data {
                                            id
                                            attributes {
                                                name
                                                macros {
                                                    fat
                                                    carbs
                                                    kcals
                                                    protein
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    useEffect(() => {
        async function fetchMealData() {
            setIsLoading(true);

            const instanceResponse = await axiosAuthFetch(`${API_GRAPHQL}`, { query: mealInstanceQuery }, 'POST');
            const mealInstances = instanceResponse.data.mealInstances.data.map(instance => ({
                ...instance,
                isMealInstance: true,
            }));

            const mealResponse = await axiosAuthFetch(`${API_GRAPHQL}`, { query: mealQuery }, 'POST');
            const meals = mealResponse.data.meals.data.map(meal => ({
                ...meal,
                isMealInstance: false,
            }));

            setAllMeals([...mealInstances, ...meals]);
            setIsLoading(false);
        }
        fetchMealData();
        //eslint-disable-next-line
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className={`${styles.container} appearing-container`}>
            <MealsPage meals={allMeals} />
        </div>
    );
}
