import React from 'react';
import styles from './register.module.css';
import RegisterCoachForm from '../../global/forms/registerCoach';
import Logo from '../../../images/logo.png';
import Image from 'react-bootstrap/Image';

export default function RegisterCoachPage(){
    

    return(
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <Image fluid alt="Limitless Logo" src={Logo} />
            </div>
            <div className={styles.container2}>
                <RegisterCoachForm />
            </div>
        </div>
    )
}