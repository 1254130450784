export default function searchObject(obj, searchText) {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            if (searchObject(obj[key], searchText)) {
                return true;
            }
        } else if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
    }
    return false;
}

// export function searchObjectWords(obj, searchWords) {
//     if (!Array.isArray(searchWords)) {
//         searchWords = [searchWords];
//     }

//     for (const key in obj) {
//         if (typeof obj[key] === 'object') {
//             if (searchObject(obj[key], searchWords)) {
//                 return true;
//             }
//         } else if (typeof obj[key] === 'string') {
//             const value = obj[key].toLowerCase();
//             const found = searchWords.some(word => value.includes(word.toLowerCase()));
//             if (found) {
//                 return true;
//             }
//         }
//     }
//     return false;
// }

export function filterObjects(searchText, objects) {

    if (!Array.isArray(objects)) {
        return [];
    }

    if(typeof searchText !== 'string') {
        return objects;
    }
    const searchWords = searchText.split(" ").filter(word => word !== "");
    
    return objects.filter(obj => {
        const values = Object.values(obj);
        const stringifiedValues = JSON.stringify(values).toLowerCase();
        
        return searchWords.every(word => stringifiedValues.includes(word.toLowerCase()));
    });
}