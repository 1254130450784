import React from 'react';
import styles from './styles.module.css';
import CoachCommunication from '../../global/communication/coachSide';
import { getLocalStorageItem } from '../../global/storage';

export default function CommunicationPage() {
    const userInfo = getLocalStorageItem('limitless-user');
    const user = userInfo?.user;
    return(
        <div className={styles.communicationsContainer}>
                    <CoachCommunication user={user} />
                </div>
    )
}