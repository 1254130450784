// import React, {useState} from 'react';
// import Image from 'react-bootstrap/Image';
// import Modal from 'react-bootstrap/Modal';
// import css from './clientCard.module.css';
// import defaultImage from '../../../images/blue_man.png';
// import { axiosAuthFetch } from '../../../helpers/axios';
// import { API_BASE_URL } from '../../../constants';

// const ClientCards = ({ clients, setClients }) => {
//     const [selectedClient, setSelectedClient] = useState(null);
//     const [showModal, setShowModal] = useState(false);

//     const handleClientClick = (client) => {
//         setSelectedClient(client);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//         setSelectedClient(null);
//     };

//     console.log('clients', clients);

//     const getFormattedDate = (client) => {
//         let formattedDate = "";
//         const workout_sessions = client?.attributes?.workout_sessions?.data || [];
//         if (workout_sessions.length > 0) {
//             const datePerformed = new Date(workout_sessions[workout_sessions.length - 1].attributes.date_performed);
//             const months = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
//             const day = datePerformed.getDate().toString().padStart(2, '0');
//             const month = months[datePerformed.getMonth()];
//             formattedDate = `${day}. ${month}`;
//         }
//         return formattedDate;
//     };

//     const toggleClientBlockStatus = async () => {
//     if (!selectedClient) return;

//     const newBlockedStatus = !selectedClient.attributes.isBlockedByCoach;
//     const action = newBlockedStatus ? "blokkere" : "fjerne blokkeringen av";

//     // Bekreftelsesdialog
//     const isConfirmed = window.confirm(`Er du sikker på at du vil ${action} ${selectedClient.attributes.username}?`);
//     if (!isConfirmed) return; // Avbryt hvis brukeren ikke bekrefter

//     try {
//         const response = await axiosAuthFetch(`${API_BASE_URL}/users/${selectedClient.id}`, {
//             data: {
//                 isBlockedByCoach: newBlockedStatus
//             },
//         }, 'PUT');

//         console.log(response);

//         setSelectedClient((prevClient) => ({
//             ...prevClient,
//             attributes: {
//                 ...prevClient.attributes,
//                 isBlockedByCoach: newBlockedStatus,
//             },
//         }));

//         setClients((prevClients) =>
//             prevClients.map((client) =>
//                 client.id === selectedClient.id
//                     ? { ...client, attributes: { ...client.attributes, isBlockedByCoach: newBlockedStatus } }
//                     : client
//             )
//         );
//     } catch (error) {
//         console.error("Error updating client block status:", error);
//         alert("Kunne ikke oppdatere blokkeringstilstanden. Prøv igjen senere.");
//     }
// };

//     return(
//             <div className={css.cardContainer}>
//                 {clients.length > 0 ? (
//                     clients.map((client) => {
//                         const { username } = client.attributes;
//                         const imageUrl = client.attributes.profile_picture?.data?.attributes?.url || defaultImage;

//                         return (
//                             <div
//                                 key={client.id}
//                                 className={css.card}
//                                 onClick={() => handleClientClick(client)}
//                             >
//                                 <div className={css.image}>
//                                     <div className={css.profilePictureContainer}>
//                                         <Image className={css.clientProfilePicture} fluid alt="client-image" src={imageUrl} />
//                                     </div>
//                                 </div>
//                                 <div className={css.infoDiv}>
//                                     <div className={css.name}>{username}</div>
//                                 </div>
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <div className={css.noClients}>
//                         Du har ingen klienter.
//                     </div>
//                 )}
//                 {/* Modal for å vise klientinformasjon */}
//             <Modal size="lg" className={css.modal} show={showModal} onHide={handleCloseModal}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Klientdetaljer</Modal.Title>
//                 </Modal.Header>
//                 {selectedClient && (
//                     <Modal.Body>
//                         <div className={css.modalBody}>
//                             <div className={css.modalImageContainer}>
//                                 <Image className={css.modalProfilePicture} fluid alt="client-image" src={selectedClient.attributes.profile_picture?.data?.attributes?.url || defaultImage } />
//                             </div>
//                             <div className={css.modalInfo}>
//                                 <p><strong>Brukernavn:</strong> {selectedClient.attributes.username}</p>
//                                 <p><strong>Programmer:</strong> {selectedClient.attributes.programs?.data?.length > 0 ? selectedClient.attributes.programs.data.map(prog => prog.attributes.programName).join(', ') : "Ingen programmer"}</p>
//                                 <p><strong>Matplan:</strong> {selectedClient.attributes.matplan?.data?.attributes?.Name ? selectedClient.attributes.matplan.data.attributes.Name : "Ingen matplan"}</p>
//                                 <p><strong>Antall økter:</strong> {selectedClient.attributes.workout_sessions?.data?.length || "Ingen treningsøkter"}</p>
//                                 <p><strong>Siste økt:</strong> {selectedClient.attributes.workout_sessions?.data?.length > 0 ? `${getFormattedDate(selectedClient)}` : "Ingen økter"}</p>
//                             </div>
//                         </div>
//                     </Modal.Body>
//                 )}
//                     <button className={css.toggleBlockButton} onClick={toggleClientBlockStatus}>
//                         {selectedClient?.attributes?.isBlockedByCoach ? "Fjern blokkering" : "Blokker klient"}
//                     </button>
//                 <Modal.Footer>
//                     <div className={css.closeButton} onClick={handleCloseModal}>
//                         Lukk
//                     </div>
//                 </Modal.Footer>
//             </Modal>
//             </div>
//     )
// };

// export default ClientCards;

import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import css from './clientCard.module.css';
import defaultImage from '../../../images/blue_man.png';
// import { axiosAuthFetch } from '../../../helpers/axios';
// import { API_BASE_URL } from '../../../constants';

const ClientCards = ({ clients, setClients }) => {
    const [selectedClient, setSelectedClient] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleClientClick = (client) => {
        setSelectedClient(client);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedClient(null);
    };

    const getFormattedDate = (client) => {
        let formattedDate = "";
        const workout_sessions = client?.attributes?.workout_sessions?.data || [];
        if (workout_sessions.length > 0) {
            const datePerformed = new Date(workout_sessions[workout_sessions.length - 1].attributes.date_performed);
            const months = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
            const day = datePerformed.getDate().toString().padStart(2, '0');
            const month = months[datePerformed.getMonth()];
            formattedDate = `${day}. ${month}`;
        }
        return formattedDate;
    };

    // const toggleClientBlockStatus = async () => {
    //     if (!selectedClient) return;

    //     const newBlockedStatus = !selectedClient.attributes.isBlockedByCoach;
    //     const action = newBlockedStatus ? "blokkere" : "fjerne blokkeringen av";

    //     // Bekreftelsesdialog
    //     const isConfirmed = window.confirm(`Er du sikker på at du vil ${action} ${selectedClient.attributes.username}?`);
    //     if (!isConfirmed) return;

    //     try {
    //         // Oppdater blokkeringstilstanden på API-et
    //         await axiosAuthFetch(`${API_BASE_URL}/users/${selectedClient.id}`, {
    //             data: {
    //                 isBlockedByCoach: newBlockedStatus
    //             },
    //         }, 'PUT');

    //         // Oppdater lokal state
    //         setSelectedClient((prevClient) => ({
    //             ...prevClient,
    //             attributes: {
    //                 ...prevClient.attributes,
    //                 isBlockedByCoach: newBlockedStatus,
    //             },
    //         }));

    //         // Oppdater klientlisten
    //         setClients((prevClients) =>
    //             prevClients.map((client) =>
    //                 client.id === selectedClient.id
    //                     ? { ...client, attributes: { ...client.attributes, isBlockedByCoach: newBlockedStatus } }
    //                     : client
    //             )
    //         );
    //     } catch (error) {
    //         console.error("Error updating client block status:", error);
    //         alert("Kunne ikke oppdatere blokkeringstilstanden. Prøv igjen senere.");
    //     }
    // };

    return (
        <div className={css.cardContainer}>
            {clients.length > 0 ? (
                clients.map((client) => {
                    const { username } = client.attributes;
                    const imageUrl = client.attributes.profile_picture?.data?.attributes?.url || defaultImage;

                    return (
                        <div
                            key={client.id}
                            className={css.card}
                            onClick={() => handleClientClick(client)}
                        >
                            <div className={css.image}>
                                <div className={css.profilePictureContainer}>
                                    <Image className={css.clientProfilePicture} fluid alt="client-image" src={imageUrl} />
                                </div>
                            </div>
                            <div className={css.infoDiv}>
                                <div className={css.name}>{username}</div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className={css.noClients}>
                    Du har ingen klienter.
                </div>
            )}
            
            {/* Modal for å vise klientinformasjon */}
            <Modal size="lg" className={css.modal} show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Klientdetaljer</Modal.Title>
                </Modal.Header>
                {selectedClient && (
                    <Modal.Body>
                        <div className={css.modalBody}>
                            <div className={css.modalImageContainer}>
                                <Image className={css.modalProfilePicture} fluid alt="client-image" src={selectedClient.attributes.profile_picture?.data?.attributes?.url || defaultImage} />
                            </div>
                            <div className={css.modalInfo}>
                                <p><strong>Brukernavn:</strong> {selectedClient.attributes.username}</p>
                                <p><strong>Programmer:</strong> {selectedClient.attributes.programs?.data?.length > 0 ? selectedClient.attributes.programs.data.map(prog => prog.attributes.programName).join(', ') : "Ingen programmer"}</p>
                                <p><strong>Matplan:</strong> {selectedClient.attributes.matplan?.data?.attributes?.Name ? selectedClient.attributes.matplan.data.attributes.Name : "Ingen matplan"}</p>
                                <p><strong>Antall økter:</strong> {selectedClient.attributes.workout_sessions?.data?.length || "Ingen treningsøkter"}</p>
                                <p><strong>Siste økt:</strong> {selectedClient.attributes.workout_sessions?.data?.length > 0 ? `${getFormattedDate(selectedClient)}` : "Ingen økter"}</p>
                                {/* <p><strong>Status:</strong> {selectedClient.attributes.isBlockedByCoach ? "Blokkert" : "Godkjent"}</p> */}
                            </div>
                        </div>
                        {/* <button className={css.toggleBlockButton} onClick={toggleClientBlockStatus}>
                            {selectedClient?.attributes?.isBlockedByCoach ? "Fjern blokkering" : "Blokker klient"}
                        </button> */}
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <div className={css.closeButton} onClick={handleCloseModal}>
                        Lukk
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ClientCards;
