import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styles from './resetPassword.module.css';
import { API_BASE_URL } from '../../../constants';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const location = useLocation();

  // Funksjon for å hente token fra URL-en
  const query = new URLSearchParams(location.search);
  const token = query.get('code');
  console.log(token);
  console.log('hei')

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Passordene samsvarer ikke.");
      return;
    }

    try {
      // Send forespørselen til Strapi for å tilbakestille passordet
      const response = await axios.post(`${API_BASE_URL}/auth/reset-password`, {
        code: token,
        password,
        passwordConfirmation: confirmPassword,
      });

      console.log(response);

      setSuccessMessage("Passordet ditt er endret!");
      setTimeout(() => {
        window.location.href="https://ll-test.netlify.app"; // Send brukeren til innloggingssiden etter vellykket reset
      }, 3000);

    } catch (error) {
      setErrorMessage("Noe gikk galt. Vennligst prøv igjen.");
    }
  };

  return (
    <div className={styles.container}>
      <h1>Tilbakestill passord</h1>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      <form className={styles.form} onSubmit={handleSubmit}>
        <label>
          Nytt passord:
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.input}
          />
        <label>
          Bekreft passord:
          </label>
          <input
            className={styles.input}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        <button className={styles.button} type="submit">Endre passord</button>
      </form>
    </div>
  );
};

export default ResetPassword;