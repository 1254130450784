import React from 'react';
import styles from './styles.module.css';

export default function CoachSideMealCard({ meal, onClick }) {
    const mealAttributes = meal.attributes;
    const { name, category } = mealAttributes;
    const imageUrl = mealAttributes.meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;

    return (
        <div className={styles.card} onClick={onClick} style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className={styles.cardOverlay}>
                <h2>{name}</h2>
                <p>{category}</p>
            </div>
        </div>
    );
}